import { Component, OnInit, inject } from "@angular/core";
import { Router } from "@angular/router";
import { AppRoutes } from "src/app/routes";
import { BaseComponent } from "../base.component";

@Component({
  selector: "app-redirect",
  template: "",
})
export class RedirectComponent extends BaseComponent implements OnInit {
  private readonly router = inject(Router);

  ngOnInit(): void {
    this.router.navigateByUrl(AppRoutes.payroll.timecardApproval.routerLink);
  }
}
