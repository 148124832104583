<mat-toolbar color="primary" class="mat-elevation-z6 app-component app-container" style="height: 64px">
  <!-- height: 64px; default height is 64 except on mobile. Keep it consistent to make fixed element positioning simpler. -->
  <ng-container *ngIf="isUserLoggedIn">
    <div class="d-flex flex-sm-row align-items-center">
      <div class="d-flex align-items-center">
        <a mat-icon-button [href]="environment.siteUrls.isUrl + 'Compass/Compass'">
          <mat-icon>explore</mat-icon>
        </a>

        <button mat-icon-button (click)="openUser()" matTooltip="Quick links">
          <mat-icon>person</mat-icon>
        </button>

        <ng-container *ngIf="isHandset$ | async; else largeSearchButton">
          <button mat-icon-button (click)="openSearch()" matTooltip="Search & Navigate">
            <mat-icon class="search-icon">search</mat-icon>
          </button>
        </ng-container>

        <ng-template #largeSearchButton>
          <button mat-flat-button class="mx-3" (click)="openSearch()">
            <mat-icon class="search-icon">search</mat-icon>
            Search & Navigate
          </button>
        </ng-template>

        <button mat-icon-button *ngIf="notificationCount > 0" (click)="openNotifications()" matTooltip="Notifications">
          <mat-icon matBadge="{{ notificationCount }}" matBadgeColor="warn">notifications</mat-icon>
        </button>
      </div>

      <button mat-flat-button (click)="openApplication()" class="d-none d-sm-block ms-sm-5">
        <ng-container *ngIf="pageTitle; else noPageTitle">
          <span class="d-flex align-items-center">
            <mat-icon class="me-2">menu</mat-icon>
            {{ pageTitle }}
          </span>
        </ng-container>
        <ng-template #noPageTitle>
          <mat-icon>
            <mat-spinner color="white" [diameter]="25" />
          </mat-icon>
        </ng-template>
      </button>
      <ng-container *ngIf="hasHelp">
        <button mat-icon-button class="d-sm-none">
          <mat-icon class="help-icon">help</mat-icon>
        </button>
        <button
          mat-flat-button
          class="d-none d-sm-block align-items-center bg-success text-white"
          (click)="openHelp()"
          matTooltip="Help">
          <mat-icon style="vertical-align: middle">help</mat-icon>
          Help With This Page
        </button>
      </ng-container>
      <button mat-icon-button (click)="openApplication()" class="me-sm-5 me-3 d-sm-none">
        <ng-container *ngIf="pageTitle; else noPageTitle">
          <mat-icon class="d-sm-none" [matTooltip]="pageTitle">menu</mat-icon>
        </ng-container>
        <ng-template #noPageTitle>
          <mat-icon>
            <mat-spinner color="white" [diameter]="25" />
          </mat-icon>
        </ng-template>
      </button>
    </div>

    <span class="spacer"></span>

    <ng-container *ngIf="clientEnvironment !== AppEnvironment.Production">
      <span class="spacer"></span>
      <app-environment-chip [clientEnvironment]="clientEnvironment" [serverEnvironment]="serverEnvironment" />
    </ng-container>

    <div *ngIf="isImpersonatingUser">
      <button
        mat-flat-button
        color="warn"
        class="d-none d-md-inline ms-3"
        matTooltip="Revert Impersonation"
        (click)="abortImpersonate()">
        Impersonating {{ fullName }}
      </button>
      <button
        mat-flat-button
        color="warn"
        class="d-sm-inline d-md-none ms-3 impersonate-sm"
        [matTooltip]="'Revert Impersonation (' + fullName + ')'"
        (click)="abortImpersonate()">
        {{ userContext.initials }}
      </button>
    </div>
    <span *ngIf="clientEnvironment !== AppEnvironment.Production || isImpersonatingUser" class="spacer d-flex"></span>

    <button *ngIf="isHandset$ | async; else wideAdditionalOptions" mat-icon-button [matMenuTriggerFor]="specialMenu">
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #specialMenu>
      <button mat-menu-item (click)="openChangeLog()">
        <mat-icon>card_giftcard</mat-icon>
        Change Log
      </button>
      <button mat-menu-item (click)="openAccess()">
        <mat-icon>lock</mat-icon>
        Page Access
      </button>
      <ng-template [ngTemplateOutlet]="settingsMenuItems" />
    </mat-menu>

    <ng-template #wideAdditionalOptions>
      <div>
        <button mat-icon-button (click)="openChangeLog()" matTooltip="Change Log">
          <mat-icon>card_giftcard</mat-icon>
        </button>
        <button mat-icon-button (click)="openAccess()" matTooltip="Page Access">
          <mat-icon>lock</mat-icon>
        </button>
        <button mat-icon-button [matMenuTriggerFor]="settingsMenu" matTooltip="Site Options & Sign Out">
          <mat-icon>settings</mat-icon>
        </button>
        <mat-menu #settingsMenu>
          <ng-template [ngTemplateOutlet]="settingsMenuItems" />
        </mat-menu>
      </div>
    </ng-template>

    <ng-template #settingsMenuItems>
      <a mat-menu-item [href]="environment.siteUrls.isUrl + 'User/UserPreferences'">
        <mat-icon>settings</mat-icon>
        Preferences
      </a>
      <a
        *ngIf="clientEnvironment !== AppEnvironment.Production"
        mat-menu-item
        [routerLink]="AppRoutes.frameworkRoot.routerLink">
        <mat-icon>build</mat-icon>
        Framework
      </a>
      <button mat-menu-item (click)="signOut()">
        <mat-icon>logout</mat-icon>
        Sign Out
      </button>
    </ng-template>

    <ng-container *ngIf="!isUserLoggedIn">
      <span class="spacer"></span>

      <ng-container *ngIf="clientEnvironment !== AppEnvironment.Production">
        <span class="spacer"></span>
        <app-environment-chip [clientEnvironment]="clientEnvironment" [serverEnvironment]="serverEnvironment" />
      </ng-container>
      <span *ngIf="clientEnvironment !== AppEnvironment.Production" class="spacer d-flex"></span>

      <ng-container>
        <button mat-button routerLinkActive="active" [routerLink]="AppRoutes.login.routerLink">
          <mat-icon>login</mat-icon>
          Log In
        </button>
      </ng-container>
    </ng-container>
  </ng-container>
</mat-toolbar>

<ng-template #loginDialogTemplate>
  <mat-dialog-content>
    <app-login #loginControl [isDialog]="true" />
  </mat-dialog-content>
</ng-template>
