import { AfterViewInit, Component, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { BackdropClass, FdRoute, FdRouterService } from "@fd/core";
import { BaseDialogComponent } from "../../base-dialog.component";
import { IsTitleService } from "src/app/modules/shared/services/is-title.service";
import { GroupApiService } from "src/app/modules/admin/services/group-api.service";
import { UserGroupAccessDetail } from "src/app/modules/admin/models/group.model";

@Component({
  selector: "app-access-dialog",
  templateUrl: "./access-dialog.component.html",
  styleUrl: "./access-dialog.component.scss",
})
export class AccessDialogComponent extends BaseDialogComponent<{}, boolean> implements AfterViewInit {
  static readonly hashId = "access";

  @ViewChild(MatSort) sort: MatSort;

  dataSource = new MatTableDataSource<UserGroupAccessDetail>();
  roleTitle = "Role";
  columns = ["groups", "roles", "userFullName"];

  protected get backdropClass(): BackdropClass {
    return "intrinsic";
  }

  constructor(
    private titleService: IsTitleService,
    private groupService: GroupApiService,
    @Inject(MAT_DIALOG_DATA) public data: {}, private fdRouterService: FdRouterService) {
    super();
    this.setHash();
  }

  ngAfterViewInit(): void {
    const group = this.titleService.currentRouteData.roleView.split('_').slice(0, -1).join('_');
    this.subscriptions.push(this.groupService.getAccessDetails(group).subscribe((data) => {
      this.roleTitle = group.split('_').join(' ');
      this.dataSource.data = data.map((d) => {
            d.roles = d.roles.split(',').join('<br>');
            d.userFullName = d.userFullName.split(',').join('<br>');
            return d;
        });
        this.dataSource.sort = this.sort;
    }));
  }

  /** Persist the state of this dialog in a hash so that the user can refresh the page to this state. */
  private setHash() {
    const pathParts: [string, number?] = [AccessDialogComponent.hashId];
    this.fdRouterService.setFragment(new FdRoute({ pathParts }), true);
  }
}
