<div class="col-sm-3 text-center col-12" >
  <div [style.background-image]="'url(' + application.crUserImage + ')'" 
  [style]="'height: 100px!important; width:100px!important; background-repeat: no-repeat;background-position:50%;background-size:cover;'" alt="Owner Image">
  </div>
</div>
<div class="col-sm-9 col-12">
  <div *ngIf="application.userOwnerID || application.crEntityOwnerID" style="word-wrap: normal;">
    <p>Contact me with improvement ideas, issues or bugs.</p>
    <div class="p-2">
      <h4 *ngIf="application.crEntityOwnerID"><b>CR: {{ application.entityOwnerName }}</b></h4>
      <ng-container *ngIf="application.userOwnerID">
        <div>
          <mat-icon class="me-2 small-icon">person</mat-icon> <span>{{ application.ownerName }}</span>
        </div>
        <div *ngIf="application.ownerPhone">
          <mat-icon class="me-2 small-icon">phone</mat-icon> <span>{{ application.ownerPhone }}</span>
        </div>
        <div *ngIf="application.applicationEmail">
          <a mat-button class="p-0" [href]="mailto"><mat-icon class="me-2 small-icon">email</mat-icon> <span>Email Owner(s)</span></a>
        </div>
      </ng-container>
    </div>
  </div>
</div>