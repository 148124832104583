import { Component, Input } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { Router } from "@angular/router";
import { IRouteInfo } from "@fd/core";
import { BaseComponent } from "../base.component";
import { AppRoutes, RouteInfo } from "src/app/routes";

@Component({
  selector: "app-navigation",
  templateUrl: "./app-navigation.component.html",
  styleUrl: "./app-navigation.component.scss",
})
export class AppNavigationComponent extends BaseComponent {
  @Input() appTitle: string;
  @Input() drawer: MatSidenav;

  root = AppRoutes.root;

  constructor(private router: Router) {
    super();
  }

  routerLink(link: IRouteInfo) {
    if (link instanceof RouteInfo) {
      return link.buildRouterLink();
    }

    return null;
  }

  queryParams(link: IRouteInfo) {
    if (link instanceof RouteInfo) {
      return link.buildQueryParams(link.data.defaultQueryParams);
    }
  }

  anyDescendentsActive(link: IRouteInfo): boolean {
    return link.children.some(childLink => {
      if (childLink.children) {
        return childLink.children.length && this.anyDescendentsActive(childLink);
      }

      return (
        childLink.routerLink &&
        this.router.isActive(childLink.routerLink, {
          paths: "subset",
          queryParams: "subset",
          fragment: "ignored",
          matrixParams: "ignored",
        })
      );
    });
  }

  hasAccess(link: IRouteInfo) {
    if (link instanceof RouteInfo) {
      return (
        link.data.allowAnonymous ||
        (this.authService.currentUser?.hasAnyViewRole(link.data.roleArea) &&
          this.authService.currentUser?.isInRole(link.data.roleView))
      );
    }

    return true;
  }
}
