import { Injectable } from "@angular/core";
import { ControllerBase } from "@fd/core";
import { Observable } from "rxjs";
import { NotificationModel } from "src/app/modules/shared/models/common/notification.model";

@Injectable({
  providedIn: "root",
})
export class NotificationService extends ControllerBase<NotificationModel> {
  constructor() {
    super("Common/Notification");
  }

  get(): Observable<NotificationModel[]> {
    return super.$get<NotificationModel[]>();
  }

  getCount(): Observable<number> {
    return super._count();
  }
}
