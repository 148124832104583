import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { Router } from "@angular/router";
import { of, Subscription } from "rxjs";
import { catchError, delay } from "rxjs/operators";
import { AccountHubService } from "src/app/modules/account/services/account-hub.service";
import { AppEnvironment, FdRoute, FdRouterService, FdSnackBar, LocalStoreManager } from "@fd/core";
import { environment } from "src/environments/environment";
import { BaseComponent } from "../base.component";
import { LoginComponent } from "../login/login.component";
import { AccessDialogComponent } from "../toolbar/access-dialog/access-dialog.component";
import { ApplicationDialogComponent } from "../toolbar/application-dialog/application-dialog.component";
import { ChangeLogDialogComponent } from "../toolbar/change-log-dialog/change-log-dialog.component";
import { HelpDialogComponent } from "../toolbar/help-dialog/help-dialog.component";
import { NotificationsDialogComponent } from "../toolbar/notifications-dialog/notifications-dialog.component";
import { SearchDialogComponent } from "../toolbar/search-dialog/search-dialog.component";
import { UserDialogComponent } from "../toolbar/user-dialog/user-dialog.component";
import { NotificationService } from "src/app/modules/shared/services/api/Common/notification.service";
import { ApplicationHelpApiService } from "src/app/modules/shared/services/api/Common/application-help-api.service";
import { AppRoutes } from "src/app/routes";
import { CookieService } from "ngx-cookie-service";
import { DbKeys } from "src/app/modules/shared/enums/db-keys.enum";
import { IsTitleService } from "src/app/modules/shared/services/is-title.service";

@Component({
  selector: "app-toolbar",
  templateUrl: "./app-toolbar.component.html",
  styleUrl: "./app-toolbar.component.scss",
})
export class AppToolbarComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input() drawer: MatSidenav;
  @Input() appTitle: string;

  AppEnvironment = AppEnvironment;
  override environment = environment;

  @ViewChild("loginDialogTemplate") loginDialogTemplate: TemplateRef<any>;
  loginDialogRef: MatDialogRef<any, any>;

  isAppLoaded: boolean;
  isUserLoggedIn: boolean;
  shouldShowLoginDialog: boolean;
  removePrebootScreen: boolean;
  newNotificationCount = 0;
  pageTitle: string;
  notificationCount = 0;
  hasHelp = false;

  stickyToasties: number[] = [];

  dataLoadingConsecutiveFailures = 0;
  notificationsLoadingSubscription: any;

  @ViewChildren("loginControl")
  dialogLoginControls: QueryList<any>;

  loginControl: LoginComponent;
  clientEnvironment = environment.configuration;
  refreshAccountSubscription: Subscription;

  get fullName(): string {
    return this.authService.currentUser
      ? `${this.authService.currentUser.firstName} ${this.authService.currentUser.lastName}`
      : "";
  }

  get isImpersonatingUser(): boolean {
    return this.authService.currentUser?.impersonatingIdentityId ? true : false;
  }

  get serverEnvironment() {
    return AppEnvironment[this.cookieService.get("Environment") as keyof typeof AppEnvironment]; // the server responds with the initial page load with an Environment cookie
  }

  get showLogin(): boolean {
    return this.router.url !== AppRoutes.login.routerLink && !this.authService.isLoggedIn;
  }

  constructor(
    private accountHubService: AccountHubService,
    private cookieService: CookieService,
    private localStorage: LocalStoreManager,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: FdSnackBar,
    public fdRouterService: FdRouterService,
    private appTitleService: IsTitleService,
    private notificationService: NotificationService,
    private applicationHelpApiService: ApplicationHelpApiService
  ) {
    super();

    this.fdRouterService.navigationEnd$.pipe(delay(100)).subscribe(() => {
      this.pageTitle = this.appTitleService.getTitle().replace(" - " + this.appTitle, "");
    });

    // subscribe to fragment events to re-open toolbar dialogs
    this.fdRouterService.fragment$.subscribe(route => {
      switch (route?.pathParts[0]) {
        case ChangeLogDialogComponent.hashId:
          this.openChangeLog(route);
          break;
        case AccessDialogComponent.hashId:
          this.openAccess();
          break;
        case NotificationsDialogComponent.hashId:
          this.openNotifications();
          break;
        case UserDialogComponent.hashId:
          this.openUser();
          break;
        case SearchDialogComponent.hashId:
          this.openSearch(route);
          break;
        case ApplicationDialogComponent.hashId:
          this.openApplication();
          break;
        case HelpDialogComponent.hashId:
          this.openHelp();
          break;
      }
    });

    // dynamically update the menu title on navigation end
    this.fdRouterService.navigationEnd$.subscribe(navigationEnd => {
      if (this.userContext != null) {
        this.setNotificationsCount();
        const parts = navigationEnd.url.split(/(\/|\?|#)/);
        this.applicationHelpApiService.count(parts[2], parts[4]).subscribe(count => (this.hasHelp = count > 0));
      }
    });
  }

  ngAfterViewInit() {
    this.dialogLoginControls.changes.subscribe((controls: QueryList<any>) => {
      controls.forEach(control => {
        if (control) {
          if (control instanceof LoginComponent) {
            this.loginControl = control;
            this.loginControl.dialogClosedCallback = () => this.loginDialogRef.close();
          }
        }
      });
    });
  }

  ngOnInit() {
    this.isUserLoggedIn = this.authService.isLoggedIn;

    if (this.isUserLoggedIn) {
      this.authService
        .refreshUser()
        .pipe(
          catchError(err => {
            if (err.status === 401) {
              this.signOut();
            }

            return of(false);
          })
        )
        .subscribe();
    }

    this.finalizeInit();

    if (
      this.drawer.opened &&
      this.localStorage.exists(DbKeys.DrawerOpen) &&
      !this.localStorage.getDataObject<boolean>(DbKeys.DrawerOpen)
    ) {
      this.drawer.toggle();
    }
  }

  signOut() {
    this.authService.signOut().subscribe();
    this.authService.redirectLogoutUser();
  }

  abortImpersonate() {
    this.authService.abortImpersonate().subscribe();
  }

  public toggleDrawer() {
    this.drawer.toggle();
    this.localStorage.saveData(this.drawer.opened, DbKeys.DrawerOpen);
  }

  private finalizeInit() {
    this.isAppLoaded = true;
    this.removePrebootScreen = true;

    if (!this.isUserLoggedIn && !window.location.pathname.includes("external-login-callback")) {
      this.authService.setReturnUrl();
    }

    this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
      this.isUserLoggedIn = isLoggedIn;

      if (this.authService.isLoggedIn) {
        if (!this.refreshAccountSubscription) {
          this.refreshAccountSubscription = this.accountHubService.onRefresh().subscribe();
        }
      } else {
        this.refreshAccountSubscription?.unsubscribe();
        this.refreshAccountSubscription = null;
      }
    });
  }

  comingSoon() {
    this.snackBar.openAccent("Coming soon!");
  }

  getYear() {
    return new Date().getUTCFullYear();
  }

  get appEnvironment(): AppEnvironment {
    return this.serverEnvironment === undefined ? this.environment.configuration : this.serverEnvironment;
  }

  openChangeLog(route: FdRoute = null) {
    this.dialog
      .open(ChangeLogDialogComponent, {
        backdropClass: "align-top",
        data: { route },
      })
      .beforeClosed()
      .subscribe(() => {
        this.fdRouterService.replaceFragment();
      });
  }

  openAccess() {
    this.dialog
      .open(AccessDialogComponent, {
        backdropClass: "align-top",
        data: {},
      })
      .beforeClosed()
      .subscribe(() => {
        this.fdRouterService.replaceFragment();
      });
  }

  openNotifications() {
    this.dialog
      .open(NotificationsDialogComponent, {
        backdropClass: "align-top",
        data: {},
      })
      .beforeClosed()
      .subscribe(() => {
        this.fdRouterService.replaceFragment();
      });
  }

  openUser() {
    this.dialog
      .open(UserDialogComponent, {
        backdropClass: "align-top",
        data: {},
      })
      .beforeClosed()
      .subscribe(() => {
        this.fdRouterService.replaceFragment();
      });
  }

  openSearch(route: FdRoute = null) {
    this.dialog
      .open(SearchDialogComponent, {
        data: { route },
      })
      .beforeClosed()
      .subscribe(() => {
        this.fdRouterService.replaceFragment();
      });
  }

  openApplication() {
    this.dialog
      .open(ApplicationDialogComponent, {
        backdropClass: ["align-top", "lg"],
      })
      .beforeClosed()
      .subscribe(() => {
        this.fdRouterService.replaceFragment();
      });
  }

  openHelp() {
    this.dialog
      .open(HelpDialogComponent, {
        backdropClass: "align-top",
      })
      .beforeClosed()
      .subscribe(() => {
        this.fdRouterService.replaceFragment();
      });
  }

  setNotificationsCount() {
    this.notificationService.getCount().subscribe(count => (this.notificationCount = count));
  }
}
