<div mat-dialog-title>
  <h3>{{ roleTitle }}</h3>
</div>

<mat-dialog-content>
  <table mat-table [dataSource]="dataSource" matSort matSortActive="role" matSortDirection="asc">
    <ng-container matColumnDef="groups">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Groups</th>
      <td mat-cell *matCellDef="let row">
        {{ row.groups }}
      </td>
    </ng-container>

    <ng-container matColumnDef="roles">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Roles</th>
      <td mat-cell *matCellDef="let row">
        <span [innerHTML]="row.roles"></span>
      </td>
    </ng-container>

    <ng-container matColumnDef="userFullName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
      <td mat-cell *matCellDef="let row">
        <span [innerHTML]="row.userFullName"></span>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>
  </table>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="close()" cdkFocusInitial>Close</button>
</mat-dialog-actions>
