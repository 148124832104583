import { inject, Injectable, OnDestroy } from "@angular/core";
import { AppRoutes } from "src/app/routes";
import { UserRoles } from "../modules/shared/enums/user-roles.enum";
import { UserDtoModel } from "../modules/account/models/user-dto.model";
import { AuthService } from "../modules/shared/services/auth.service";
import { FdBaseDialogComponent, FdTitleService } from "@fd/core";
import { IEnvironments } from "src/environments/environment.definition";
import { Router } from "@angular/router";

@Injectable()
export abstract class BaseDialogComponent<Input = never, Output = never> extends FdBaseDialogComponent<
  UserDtoModel,
  AuthService,
  Input,
  Output
> implements OnDestroy {
  // constants
  protected readonly AppRoutes = AppRoutes;
  protected readonly UserRoles = UserRoles;
  override readonly environment: IEnvironments;

  private readonly localRouter = inject(Router);
  private readonly localTitleService = inject(FdTitleService);

  constructor() {
    super();
  }
  ngOnDestroy(): void {
    if (!this.localTitleService.currentRouteData) {
      let route = this.localRouter.routerState.root.snapshot;
      while (route.firstChild) {
        route = route.firstChild;
      }
      this.localTitleService.setTitleFromRouteData(route.data);
    }
  }
}
