import { Component, Input, OnDestroy, OnInit, inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from "../../../environments/environment";
import { UserLogin } from "./user-login.model";
import { AppRoutes } from "src/app/routes";
import { AppEnvironment, FdSnackBar, LocalStoreManager } from "@fd/core";
import { AuthService } from "src/app/modules/shared/services/auth.service";
import { DbKeys } from "src/app/modules/shared/enums/db-keys.enum";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrl: "./login.component.scss",
})
export class LoginComponent implements OnInit, OnDestroy {
  @Input() isDialog = false;
  private readonly snackBar = inject(FdSnackBar);
  private readonly authService = inject(AuthService);
  private readonly localStoreManager = inject(LocalStoreManager);
  private readonly route = inject(ActivatedRoute);

  private readonly environment = environment;
  readonly AppRoutes = AppRoutes;

  userLogin = new UserLogin();
  isLoading = false;
  formResetToggle = true;
  dialogClosedCallback: () => void;
  loginStatusSubscription: any;

  showRegister = false;
  showMicrosoftLogin = true;
  showGoogleLogin = false;
  showLocalLogin = true;
  showForgotPassword = true;

  get isDevelopment() {
    return this.environment.configuration === AppEnvironment.Development;
  }

  ngOnInit() {
    //check if query string status is faild using router
    this.route.queryParams.subscribe(params => {
      if (params.status === "failed") {
        this.snackBar.openWarn("Login failed. Please try again.");
      } else if (params.status === "inactive") {
        this.snackBar.open("Your account is inactive.");
      }
    });
    this.userLogin.rememberMe = this.authService.rememberMe;

    const { wholePart } = this.authService.getReturnUrl();
    this.localStoreManager.saveSessionData(wholePart, DbKeys.ReturnUrl);

    if (this.getShouldRedirect()) {
      this.authService.redirectLoginUser();
    } else {
      this.loginStatusSubscription = this.authService.getLoginStatusEvent().subscribe(() => {
        if (this.getShouldRedirect()) {
          this.authService.redirectLoginUser();
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.loginStatusSubscription) {
      this.loginStatusSubscription.unsubscribe();
    }
  }

  getShouldRedirect() {
    return !this.isDialog && this.authService.isLoggedIn;
  }

  showErrorAlert(message: string) {
    this.snackBar.open(message);
  }

  closeDialog() {
    if (this.dialogClosedCallback) {
      this.dialogClosedCallback();
    }
  }

  signIn(backdoor: boolean = false) {
    this.isLoading = true;
    this.snackBar.open("Attempting login...");

    this.authService
      .signIn(this.userLogin.userName, this.userLogin.password, this.userLogin.rememberMe, backdoor)
      .subscribe(user => {
        setTimeout(() => {
          this.snackBar.dismiss();
          this.isLoading = false;
          this.reset();

          if (!this.isDialog) {
            this.snackBar.open(`Welcome ${user.firstName}!`);
          } else {
            this.snackBar.open(`Session for ${user.firstName} restored!`);
            setTimeout(() => {
              this.snackBar.openAccent(`Session for ${user.firstName} restored! Please try your last operation again.`);
            }, 500);

            this.closeDialog();
          }
        }, 500);
      })
      .add(() => (this.isLoading = false));
  }

  mapLoginErrorMessage(error: string) {
    if (error === "invalid_username_or_password") {
      return "Invalid username or password";
    }

    if (error === "invalid_grant") {
      return "This account has been disabled";
    }

    return error;
  }

  reset() {
    this.formResetToggle = false;

    setTimeout(() => {
      this.formResetToggle = true;
    });
  }

  rememberMeChange() {
    this.authService.rememberMe = this.userLogin.rememberMe;
  }
}
