export enum ShipmentStatus {
  All = 0,
  Created = 1,
  InProgress = 2,
  PartiallyShipped = 3,
  Shipped = 4,
  Closed = 5,
  Staged = 9, //Scanned/Shipped >= Total Parts Needed
  Active = 10, //Created - InProgress - PartiallyShipped
  AllShipped = 11, //PartiallyShipped - Shipped
}
