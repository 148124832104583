import { Injectable } from "@angular/core";
import { ControllerBase } from "@fd/core";
import { Observable } from "rxjs";
import { ApplicationInterface } from "src/app/modules/shared/models/common/appliaction.model";

@Injectable({ providedIn: "root" })
export class ApplicationApiService extends ControllerBase<ApplicationInterface> {
  constructor() {
    super("Common/Application");
  }

  //#region super implementation
  //get() {
  //  return super.$get();
  //}
  //
  //getById(id: string) {
  //  return super.$getById(id);
  //}
  //
  //post(model: ApplicationInterface) {
  //  return super.$post(model);
  //}
  //
  //put(model: ApplicationInterface) {
  //  return super$put(model);
  //}
  //
  //delete(id: string) {
  //  return super.$delete(id);
  //}

  get(area: string): Observable<ApplicationInterface> {
    return super._get<ApplicationInterface>({ url: "single", query: { areaName: area } });
  }

  count(searchTerm: string = ""): Observable<number> {
    return super._count({ query: { searchTerm } });
  }
  //#endregion

  search(searchTerm: string, top?: number): Observable<ApplicationInterface[]> {
    return super._getBySearch<ApplicationInterface[]>(searchTerm, { query: { top } });
  }
}
