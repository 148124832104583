import { Injectable } from "@angular/core";
import { ControllerBase } from "@fd/core";
import { Observable } from "rxjs";
import { ChangeLogInterface } from "src/app/modules/shared/models/utility/change-log.model";

@Injectable({ providedIn: "root" })
export class ChangeLogApiService extends ControllerBase<ChangeLogInterface> {
  constructor() {
    super("Utility/ChangeLog");
  }

  //#region super implementation
  get(releaseDate: Date): Observable<ChangeLogInterface[]> {
    return super.$get<ChangeLogInterface[]>({ releaseDate });
  }

  //getById(id: string) {
  //  return super.getById(id);
  //}

  //post(model: GroupInterface) {
  //  return super.post(model);
  //}

  //put(model: GroupInterface) {
  //  return super.put(model);
  //}

  //delete(id: string) {
  //  return super.delete(id);
  //}
  //#endregion
}
