<div mat-dialog-title>
  <h2>Notifications</h2>
</div>

<mat-dialog-content class="list-group">
  <div *ngFor="let notification of notifications; let index = index">
    <h2
      class="border-bottom border-2 list-group-item mb-2 pt-3"
      *ngIf="!notifications[index - 1] || notification.applicationName !== notifications[index - 1].applicationName">
      {{ notification.applicationName }}
    </h2>
    <li class="d-flex justify-content-between align-items-start border-bottom border-1">
      <a
        *ngIf="notification.absoluteUrl.startsWith('/'); else externalLink"
        class="w-100"
        mat-button
        (click)="close()"
        [routerLink]="notification.absoluteUrl.toLowerCase()">
        <span class="float-start">{{ notification.viewName }}</span>
        <span class="float-end">{{ notification.notificationCount }}</span>
      </a>

      <ng-template #externalLink>
        <a [href]="notification.absoluteUrl" class="w-100" mat-button target="_blank">
          <span class="float-start">{{ notification.viewName }}</span>
          <span class="float-end">{{ notification.notificationCount }}</span>
        </a>
      </ng-template>
    </li>
  </div>
</mat-dialog-content>
