import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Title } from "@angular/platform-browser";
import { tap } from "rxjs/operators";
import { ApplicationInterface } from "src/app/modules/shared/models/common/appliaction.model";
import { UserFavoriteInterface } from "src/app/modules/shared/models/common/user-favorite.model";
import { ViewInterface } from "src/app/modules/shared/models/common/view.model";
import { FdRoute, FdRouterService } from "@fd/core";
import { BaseComponent } from "../../base.component";
import { ApplicationApiService } from "src/app/modules/shared/services/api/Common/application-api.service";
import { UserFavoriteApiService } from "src/app/modules/shared/services/api/Common/user-favorite-api.service";

@Component({
  selector: "app-application-dialog",
  templateUrl: "./application-dialog.component.html",
  styleUrl: "./application-dialog.component.scss",
})
export class ApplicationDialogComponent extends BaseComponent implements OnInit {
  static readonly hashId = "app";
  application: ApplicationInterface;
  get currentPath() {
    return location.pathname;
  }
  get title() {
    return this.titleService.getTitle().replace(" - InnoSuite", "");
  }
  isLoading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {},
    private dialogRef: MatDialogRef<ApplicationDialogComponent>,
    private fdRouterService: FdRouterService,
    private applicationApiService: ApplicationApiService,
    private userFavoriteApiService: UserFavoriteApiService,
    private titleService: Title
  ) {
    super();
    this.setHash(false);
  }

  ngOnInit(): void {
    const area = location.pathname.split("/")[1];
    this.applicationApiService
      .get(area)
      .pipe(tap(app => (this.application = app)))
      .subscribe(() => (this.isLoading = false));
  }

  toggleBookmark(view: ViewInterface) {
    if ((view.isFavorite = !view.isFavorite)) {
      this.addFavorite(view.id, this.application.id);
    } else {
      this.removeFavorite(view.id, this.application.id);
    }
  }

  addFavorite(id: number, applicationId: number) {
    this.userFavoriteApiService.post({ id, applicationId } as UserFavoriteInterface).subscribe();
  }

  removeFavorite(id: number, applicationId: number) {
    this.userFavoriteApiService.delete({ id, applicationId } as UserFavoriteInterface).subscribe();
  }

  close() {
    this.dialogRef.close();
  }

  private setHash(replaceState: boolean) {
    const pathParts: [string, string] = [ApplicationDialogComponent.hashId, ""];
    this.fdRouterService.setFragment(new FdRoute({ pathParts }), replaceState);
  }
}
