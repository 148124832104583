<mat-autocomplete autoActiveFirstOption [panelWidth]="panelWidth" [displayWith]="displayWith">
  <ng-container *ngIf="filtering; else notFiltering">
    <mat-option [disabled]="true" *ngIf="control.value?.length">
      <div #loading><ng-content select="[loading]"></ng-content></div>
      <i *ngIf="!loading.innerHTML.trim()">Loading...</i>
    </mat-option>
  </ng-container>
  <ng-template #notFiltering>
    <ng-container *ngIf="items.length; else noItems">
      <mat-option *ngFor="let item of items; index as i" [value]="item">
        <ng-container *ngIf="dataTemplate; else defaultContent">
          <ng-container *ngTemplateOutlet="dataTemplate; context: { $implicit: item, index: i }" />
        </ng-container>
        <ng-template #defaultContent>{{ item }}</ng-template>
      </mat-option>
    </ng-container>
    <ng-template #noItems>
      <mat-option [disabled]="true">
        <div #noResults><ng-content select="[no-results]"></ng-content></div>
        <i *ngIf="!noResults.innerHTML.trim()">No results found...</i>
      </mat-option>
    </ng-template>
  </ng-template>
</mat-autocomplete>
