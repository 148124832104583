import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { BackdropClass, FdRoute, FdRouterService } from "@fd/core";
import { environment } from "src/environments/environment";
import { BaseDialogComponent } from "../../base-dialog.component";

@Component({
  selector: "app-user-dialog",
  templateUrl: "./user-dialog.component.html",
  styleUrl: "./user-dialog.component.scss",
})
export class UserDialogComponent extends BaseDialogComponent {
  static readonly hashId = "me";
  myLinks = [
    {
      icon: "track_changes",
      link: null as any,
      url: environment.siteUrls.isUrl + "Plan/Plan/" + this.userContext.userId,
      text: "My Plan",
    },
    {
      icon: "view_module",
      link: null,
      url: environment.siteUrls.isUrl + "Evals/Eval/" + this.userContext.userId,
      text: "My Eval",
    },
    {
      icon: "groups",
      link: null,
      url: environment.siteUrls.isUrl + "Compass/Compass?pivotType=AllCoachee&ID=" + this.userContext.userId,
      text: "My Coachees",
    },
    {
      icon: "format_list_bulleted",
      link: null,
      url:
        environment.siteUrls.isUrl +
        "Compass/Compass?pivotType=AllCoachee&isFourFive=True&ID=" +
        this.userContext.userId,
      text: "My Coachees 45s",
    },
    {
      icon: "map",
      link: null,
      url: environment.siteUrls.isUrl + "Compass/Compass?pivotType=AllDivision",
      text: "All Division",
    },
    { icon: "track_changes", link: null, url: environment.siteUrls.isUrl + "Plan/Plan/1391", text: "1PP" },
    { icon: "track_changes", link: null, url: environment.siteUrls.isUrl + "Plan/Plan/199", text: "CRCR Plan" },
  ];

  protected get backdropClass(): BackdropClass {
    return "intrinsic";
  }
  constructor(private router: Router, private fdRouterService: FdRouterService) {
    super();
    this.setHash(false);
  }

  navigate(url: string) {
    this.router.navigateByUrl(url);
    this.close();
  }

  /** Persist the state of this dialog in a hash so that the user can refresh the page to this state. */
  private setHash(replaceState: boolean) {
    const pathParts: [string, number?] = [UserDialogComponent.hashId];
    this.fdRouterService.setFragment(new FdRoute({ pathParts }), replaceState);
  }
}
