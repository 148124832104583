<mat-dialog-content>
  <div class="row g-0 gap-5">
    <div class="col">
      <mat-list>
        <h3>My Coachees</h3>
        <mat-list-item>No data found.</mat-list-item>

        <h3 class="mt-2">My Division / Cell</h3>
        <mat-list-item>No data found.</mat-list-item>

        <h3 class="mt-2">My CR Activity</h3>
        <mat-list-item>No data found.</mat-list-item>
      </mat-list>
    </div>
    <div class="col-auto">
      <div *ngFor="let myLink of myLinks" (click)="navigate(myLink.link)" class="mb-2">
        <div class="d-flex align-items-end gap-3">
          <a mat-button [routerLink]="myLink.link" *ngIf="myLink.link">
            <mat-icon>{{ myLink.icon }}</mat-icon>
            {{ myLink.text }}
          </a>
          <a mat-button [href]="myLink.url" *ngIf="myLink.url">
            <mat-icon>{{ myLink.icon }}</mat-icon>
            {{ myLink.text }}
          </a>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="close()" cdkFocusInitial>Close</button>
</mat-dialog-actions>
