import { CommonModule } from "@angular/common";
import { Component, ElementRef, HostBinding, Input, Self, ViewChild } from "@angular/core";
import { ControlValueAccessor, FormControl, FormsModule, NgControl, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";

@Component({
  selector: "fd-search-form-field",
  templateUrl: "./search-form-field.component.html",
  styleUrl: "./search-form-field.component.scss",
  standalone: true,

  imports: [
    // Angular
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // Angular Material
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
  ],
})
export class SearchFormFieldComponent implements ControlValueAccessor {
  @Input() placeholder = "";
  @Input() label = "Search";
  @ViewChild(HTMLInputElement) input: ElementRef<HTMLInputElement>;

  @HostBinding("class.has-query") get hasQuery() {
    return !!this.control.value;
  }

  get control() {
    return this.ngControl.control as FormControl;
  }

  constructor(@Self() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  // Implement ControlValueAccessor
  writeValue() {}
  registerOnChange() {}
  registerOnTouched() {}
  setDisabledState?() {}

  clear() {
    this.control.patchValue("");
  }
}
