import { Injectable } from "@angular/core";
import { ControllerBase } from "@fd/core";
import { Observable } from "rxjs";
import { EntityType } from "src/app/modules/shared/enums/entity-type.enum";
import { EntityInterface } from "src/app/modules/shared/models/common/entity.model";

@Injectable({ providedIn: "root" })
export class EntityApiService extends ControllerBase<EntityInterface> {
  constructor() {
    super("Common/Entity");
  }

  //#region super implementation
  get(parentIds: number[], ...entityTypes: EntityType[]): Observable<EntityInterface[]> {
    return super._get<EntityInterface[]>({ query: { parentIds, entityTypes } });
  }

  getById(id: string): Observable<EntityInterface> {
    return super.$getById<EntityInterface>(id);
  }

  // post(model: EntityInterface) {
  //   return super.$post(model);
  // }

  // put(model: EntityInterface) {
  //   return super.$put(model);
  // }

  // delete(id: string) {
  //   return super.$delete(id);
  // }

  count(searchTerm: string, ...entityTypes: EntityType[]): Observable<number> {
    return super._count({ query: { searchTerm, entityTypes } });
  }
  //#endregion

  search(query: { searchTerm: string; top?: number }, ...entityTypes: EntityType[]): Observable<EntityInterface[]> {
    return super._getBySearch<EntityInterface[]>(query.searchTerm, { query: { entityTypes, top: query.top } });
  }
}
