import { WeekDay } from "@angular/common";
import { Component } from "@angular/core";
import { BackdropClass, FdRoute, FdRouterService } from "@fd/core";
import { tap } from "rxjs/operators";
import { ChangeLogApiService } from "src/app/modules/shared/services/api/utility/change-log-api.service";
import { BaseDialogComponent } from "../../base-dialog.component";

@Component({
  selector: "app-change-log-dialog",
  templateUrl: "./change-log-dialog.component.html",
  styleUrl: "./change-log-dialog.component.scss",
})
export class ChangeLogDialogComponent extends BaseDialogComponent<{ route: FdRoute | null }, boolean> {
  static readonly hashId = "change-log";
  isLoading = true;

  private get isDefaultState() {
    return this.date.toDateString() === new Date().toDateString();
  }

  protected get backdropClass(): BackdropClass {
    return "intrinsic";
  }

  date: Date;
  contentByTitle: Map<string, Map<string, { title: string; subtitle: string; description: string }[]>> = new Map();

  constructor(private fdRouterService: FdRouterService, private apiService: ChangeLogApiService) {
    super();
    if (this.input.route?.pathParts[1]) {
      this.date = new Date(Number(this.input.route.pathParts[1]));
    }

    if (!this.date || isNaN(this.date.getTime())) {
      this.date = new Date().fdGetDateOnly().fdGetDayOfWeek(WeekDay.Monday);
    }

    this.setContent();

    // don't replace the history state on opening the dialog so that we can use the back button to close the dialog
    this.setHash(false);
  }

  changeDate(days: number) {
    this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() + days);
    this.setContent();
    this.setHash(true);
  }

  private setContent() {
    this.apiService
      .get(this.date)
      .pipe(
        tap(items => {
          const newContentByTitle = new Map();
          items.forEach(content => {
            const contentBySubtitle = newContentByTitle.has(content.applicationName)
              ? newContentByTitle.get(content.applicationName)
              : new Map();
            const contentData = contentBySubtitle.has(content.viewName) ? contentBySubtitle.get(content.viewName) : [];

            contentData.push(content);
            contentBySubtitle.set(content.viewName, contentData);
            newContentByTitle.set(content.applicationName, contentBySubtitle);
          });
          this.contentByTitle = newContentByTitle;
        })
      )
      .subscribe()
      .add(() => (this.isLoading = false));
  }

  /** Persist the state of this dialog in a hash so that the user can refresh the page to this state. */
  private setHash(replaceState: boolean) {
    const pathParts: [string, number?] = [ChangeLogDialogComponent.hashId];

    // don't push the default state of the dialog to the hash
    if (!this.isDefaultState) {
      pathParts.push(this.date.getTime());
    }

    this.fdRouterService.setFragment(new FdRoute({ pathParts }), replaceState);
  }
}
