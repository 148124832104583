import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { BackdropClass, FdRoute, FdRouterService } from "@fd/core";
import { NotificationModel } from "src/app/modules/shared/models/common/notification.model";
import { NotificationService } from "src/app/modules/shared/services/api/Common/notification.service";
import { BaseDialogComponent } from "../../base-dialog.component";

@Component({
  selector: "app-notifications-dialog",
  templateUrl: "./notifications-dialog.component.html",
  styleUrl: "./notifications-dialog.component.scss",
})
export class NotificationsDialogComponent extends BaseDialogComponent<{}, boolean> {
  static readonly hashId = "notifications";
  notifications: NotificationModel[];

  protected get backdropClass(): BackdropClass {
    return "intrinsic";
  }

  constructor(
    private router: Router,
    private fdRouterService: FdRouterService,
    private notificationService: NotificationService
  ) {
    super();
    this.setHash(false);
    this.notificationService.get().subscribe(notificationList => {
      this.notifications = notificationList;
    });
  }

  navigate(absoluteUrl: string) {
    this.router.navigateByUrl(absoluteUrl);
    this.close();
  }

  /** Persist the state of this dialog in a hash so that the user can refresh the page to this state. */
  private setHash(replaceState: boolean) {
    const pathParts: [string, number?] = [NotificationsDialogComponent.hashId];
    this.fdRouterService.setFragment(new FdRoute({ pathParts }), replaceState);
  }
}
