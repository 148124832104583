<mat-progress-bar *ngIf="showGlobalLoadingIndicator" mode="indeterminate" />

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav mat-elevation-z1"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="showSidebar">
    <app-navigation
      *ngIf="showSidebar"
      [hidden]="!userContext"
      [drawer]="(isHandset$ | async) ? drawer : null"
      [appTitle]="appTitle" />
  </mat-sidenav>

  <mat-sidenav-content>
    <app-toolbar class="toolbar" [drawer]="drawer" [appTitle]="appTitle" />

    <div class="content-wrapper">
      <router-outlet *ngIf="!isLoadingLazyModule" />
      <fd-spinner *ngIf="isLoadingLazyModule" />
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
