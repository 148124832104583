import { AppEnvironment, ExternalRouteInfo, FdRouteInfo, FdRouteInfoData, Primitives } from "@fd/core";

import { UserRoles } from "./modules/shared/enums/user-roles.enum";
import { environment } from "src/environments/environment";
import { ShipmentStatus } from "./modules/shipping/enums/shipment-status.enum";
export class RouteInfo<PathParams extends Primitives[] = [], QueryParams extends {} = {}> extends FdRouteInfo<
  UserRoles,
  PathParams,
  QueryParams
> {}

/** These routes will only be loaded in dev/testing builds */
export class AppRoutes {
  public static readonly root = new RouteInfo(null, "", { title: "", allowAnonymous: true });

  public static readonly login = new RouteInfo(AppRoutes.root, "login", {
    title: "Login",
    showInMenu: false,
    allowAnonymous: true,
  });
  public static readonly home = new RouteInfo(AppRoutes.root, "home", { title: "Home", showInMenu: false });
  public static readonly notAuthorized = new RouteInfo(AppRoutes.root, "not-authorized", {
    title: "Not Authorized",
    showInMenu: false,
    allowAnonymous: true,
  });

  // #region Admin Module
  public static adminRoot = new RouteInfo(AppRoutes.root, "admin", { title: "Admin", roleArea: UserRoles.Admin });
  public static admin = {
    users: new RouteInfo(AppRoutes.adminRoot, "users", { title: "Users", roleView: UserRoles.Admin_Users_View }),
    securityGroups: new RouteInfo(AppRoutes.adminRoot, "security-groups", {
      title: "Security Groups",
      roleView: UserRoles.Admin_SecurityGroups_View,
    }),
  };
  // #endregion

  // #region Account Module
  public static accountRoot = new RouteInfo(null, "account", {
    title: "Account",
    showInMenu: false,
    allowAnonymous: true,
  });
  public static account = {
    registration: new RouteInfo(AppRoutes.accountRoot, "registration", {
      title: "Registration",
      allowAnonymous: true,
    }),
    externalLoginCallback: new RouteInfo(AppRoutes.accountRoot, "external-login-callback", {
      title: "Logging you in...",
      allowAnonymous: true,
    }),
    confirmEmail: new RouteInfo(AppRoutes.accountRoot, "confirm-email", {
      title: "Confirm Email",
      allowAnonymous: true,
    }),
    forgotPassword: new RouteInfo(AppRoutes.accountRoot, "forgot-password", {
      title: "Forgot Password",
      allowAnonymous: true,
    }),
    resetPassword: new RouteInfo(AppRoutes.accountRoot, "reset-password", {
      title: "Reset Password",
      allowAnonymous: true,
    }),
    profile: new RouteInfo(AppRoutes.accountRoot, "profile", { title: "Profile" }),
  };
  // #endregion

  // #region Framework Module
  public static frameworkRoot = new RouteInfo(AppRoutes.root, "framework", {
    title: "Framework",
    showInMenu: environment.configuration !== AppEnvironment.Production,
  });
  public static frameworkSwaggerRoot = new ExternalRouteInfo(AppRoutes.frameworkRoot, "Swagger", "/Swagger", {
    showInMenu: environment.configuration === AppEnvironment.Development,
    target: "swagger",
  });
  public static frameworkRinRoot = new ExternalRouteInfo(
    AppRoutes.frameworkRoot,
    "Request Inspector (Rin)",
    `${environment.localServerUrl}/rin`,
    {
      showInMenu: environment.configuration === AppEnvironment.Development,
      target: "rin",
    }
  );
  public static frameworkCoreRoot = new RouteInfo(AppRoutes.frameworkRoot, "core", {
    title: "Core",
    isNestedRoot: true,
  });
  public static frameworkControlsRoot = new RouteInfo(AppRoutes.frameworkRoot, "controls", {
    title: "Form Controls",
    isNestedRoot: true,
  });
  public static frameworkWidgetsRoot = new RouteInfo(AppRoutes.frameworkRoot, "widgets", {
    title: "Widgets",
    isNestedRoot: true,
  });
  public static frameworkLayoutsRoot = new RouteInfo(AppRoutes.frameworkRoot, "layouts", {
    title: "Layouts",
    isNestedRoot: true,
  });
  public static framework = {
    // core
    about: new RouteInfo(AppRoutes.frameworkCoreRoot, "about", { title: "About" }),
    email: new RouteInfo(AppRoutes.frameworkCoreRoot, "email", { title: "Email" }),
    errorHandling: new RouteInfo(AppRoutes.frameworkCoreRoot, "error-handling", { title: "Error Handling" }),
    dateTime: new RouteInfo(AppRoutes.frameworkCoreRoot, "date-time", { title: "Date & Time" }),
    form: new RouteInfo(AppRoutes.frameworkCoreRoot, "form", { title: "Form" }),
    files: new RouteInfo<["ico" | "csv" | "xlsx" | "pdf"]>(AppRoutes.frameworkCoreRoot, "download-file/:?fileType", {
      title: "Files",
      defaultPathParams: ["ico"],
    }),
    globalLoadingIndicator: new RouteInfo(AppRoutes.frameworkCoreRoot, "global-loading-indicator", {
      title: "Global Loading Indicator",
    }),
    invalidModelState: new RouteInfo(AppRoutes.frameworkCoreRoot, "invalid-model-state", {
      title: "Invalid Model State ",
    }),
    pipes: new RouteInfo(AppRoutes.frameworkCoreRoot, "pipes", { title: "Pipes" }),
    prototyping: new RouteInfo(AppRoutes.frameworkCoreRoot, "prototyping", { title: "Prototyping" }),
    routing: new RouteInfo<[number, string, boolean?], { age?: number; grade?: string }>(
      AppRoutes.frameworkCoreRoot,
      "routing/:id/:name/:?flag",
      {
        title: "Routing",
        defaultPathParams: [0, "_"],
        defaultQueryParams: { age: 0 },
      }
    ),
    cache: new RouteInfo(AppRoutes.frameworkCoreRoot, "cache", { title: "Cache" }),
    // form controls
    autocomplete: new RouteInfo(AppRoutes.frameworkControlsRoot, "autocomplete", { title: "Autocomplete" }),
    checkbox: new RouteInfo(AppRoutes.frameworkControlsRoot, "checkbox", { title: "Checkbox" }),
    datepicker: new RouteInfo(AppRoutes.frameworkControlsRoot, "datepicker", { title: "Date / Time Picker" }),
    number: new RouteInfo(AppRoutes.frameworkControlsRoot, "number", { title: "Number" }),
    select: new RouteInfo(AppRoutes.frameworkControlsRoot, "select", { title: "Select" }),
    text: new RouteInfo(AppRoutes.frameworkControlsRoot, "text", { title: "Text" }),
    toggle: new RouteInfo(AppRoutes.frameworkControlsRoot, "toggle", { title: "Toggle" }),
    uploadFileControl: new RouteInfo(AppRoutes.frameworkControlsRoot, "upload-file", { title: "Upload File" }),
    // widgets
    button: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "button", { title: "Button" }),
    chart: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "chart", { title: "Chart" }),
    confirmationPopover: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "confirmation-popover", {
      title: "Confirmation Popover",
    }),
    dataTables: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "data-tables", { title: "Data Tables" }),
    dialog: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "dialog", { title: "Dialog" }),
    menu: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "menu", { title: "Menu" }),
    snackBar: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "snack-bar", { title: "Snack Bar" }),
    tooltip: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "tooltip", { title: "Tooltip" }),
    // layouts
    layoutTable: new RouteInfo<[string]>(AppRoutes.frameworkLayoutsRoot, "table", {
      title: "Table",
      defaultQueryParams: { type: "sidebar" },
    }),
    layoutTableEditable: new RouteInfo(AppRoutes.frameworkLayoutsRoot, "table-editable", {
      title: "Editable Table",
      defaultQueryParams: { type: "sidebar" },
    }),
    layoutCards: new RouteInfo<[string?]>(AppRoutes.frameworkLayoutsRoot, "cards/:?view", {
      title: "Cards",
      defaultQueryParams: { type: "sidebar" },
    }),
    layoutReport: new RouteInfo<[string?]>(AppRoutes.frameworkLayoutsRoot, "report/:?view", {
      title: "Report",
      defaultQueryParams: { type: "sidebar", showViewLinks: true, showActions: false },
    }),
    layoutUtilities: new RouteInfo<[string?]>(AppRoutes.frameworkLayoutsRoot, "layout-utilities/:?view", {
      title: "Utilities",
    }),
  };
  // #endregion

  // #region Payroll Module
  public static payrollRoot = new RouteInfo(AppRoutes.root, "payroll", {
    title: "Payroll",
    roleArea: UserRoles.Payroll,
  });
  public static payroll = {
    manageIncentives: new RouteInfo(AppRoutes.payrollRoot, "manage-incentives", {
      title: "Manage Incentives",
      roleView: UserRoles.Payroll_ManageIncentives_View,
    }),
    timecardApproval: new RouteInfo(AppRoutes.payrollRoot, "timecard-approval", {
      title: "Timecard Approval",
      roleView: UserRoles.Payroll_TimecardApproval_View,
    }),
    manageGroups: new RouteInfo(AppRoutes.payrollRoot, "manage-groups", {
      title: "Manage Groups",
      roleView: UserRoles.Payroll_ManageGroups_View,
    }),
    payReport: new RouteInfo(AppRoutes.payrollRoot, "pay-report", {
      title: "Pay Report",
      roleView: UserRoles.Payroll_PayReport_View,
    }),
    payDetailsReport: new RouteInfo(AppRoutes.payrollRoot, "pay-details-report", {
      title: "Pay Details Report",
      roleView: UserRoles.Payroll_PayDetailsReport_View,
    }),
    manageTimeCards: new RouteInfo(AppRoutes.payrollRoot, "manage-time-cards", {
      title: "Manage Time Cards",
      roleView: UserRoles.Payroll_ManageTimeCards_View,
    }),
    offshiftPremiumEarnings: new RouteInfo(AppRoutes.payrollRoot, "offshift-premium-earnings", {
      title: "Offshift Premium Earnings",
      roleView: UserRoles.Payroll_OffshiftPremiumEarnings_View,
    }),
    payDetailsExport: new RouteInfo(AppRoutes.payrollRoot, "pay-details-export", {
      title: "Pay Details Export",
      roleView: UserRoles.Payroll_PayDetailsExport_View,
    }),
  };

  // #region Time Off Module
  public static timeOffRoot = new RouteInfo(AppRoutes.root, "time-off", {
    title: "Time Off",
    roleArea: UserRoles.TimeOff,
  });
  public static timeOff = {
    timeOffBalances: new RouteInfo(AppRoutes.timeOffRoot, "time-off-balances", {
      title: "Time Off Balances",
      roleView: UserRoles.TimeOff_TimeOffBalances_View,
    }),
    timeOffRequests: new RouteInfo(AppRoutes.timeOffRoot, "time-off-requests", {
      title: "Time Off Requests",
      roleView: UserRoles.TimeOff_TimeOffRequests_View,
    }),
    manageTimeOffRequests: new RouteInfo(AppRoutes.timeOffRoot, "manage-time-off-requests", {
      title: "Manage Time Off Requests",
      roleView: UserRoles.TimeOff_ManageTimeOffRequests_View,
    }),
  };
  // #endregion

  // #region Shirt Size Module
  public static shirtSizeRoot = new RouteInfo(AppRoutes.root, "shirt-size", {
    title: "Shirt Size",
    roleArea: UserRoles.ShirtSize,
  });
  public static shirtSize = {
    report: new RouteInfo(AppRoutes.shirtSizeRoot, "report", {
      title: "Report",
      roleView: UserRoles.ShirtSize_Report_View,
    }),
  };
  // #endregion

  // #region Team Module
  public static teamRoot = new RouteInfo(AppRoutes.root, "team", { title: "Team", roleArea: UserRoles.Team });
  public static team = {
    teammates: new RouteInfo(AppRoutes.teamRoot, "teammates", {
      title: "Teammates",
      roleView: UserRoles.Team_Teammates_View,
    }),
    crMarix: new RouteInfo(AppRoutes.teamRoot, "cr-matrix", {
      title: "CR Matrix",
      roleView: UserRoles.Team_CRMatrix_View,
    }),
    redbullCellStationOwnerMatrix: new RouteInfo(AppRoutes.teamRoot, "redbull-cell-station-owner-matrix", {
      title: "Redbull Cell Station Owner Matrix",
      roleView: UserRoles.Team_RedbullCellStationOwnerMatrix_View,
    }),
    findUserRoles: new RouteInfo(AppRoutes.teamRoot, "find-user-roles", {
      title: "Find User Roles",
      roleView: UserRoles.Team_FindUserRoles_View,
    }),
  };
  // #endregion

  // #region Client Tools Module
  public static clientToolsRoot = new RouteInfo(AppRoutes.root, "client-tools", {
    title: "Client Tools",
    roleArea: UserRoles.ClientTools,
  });
  public static clientTools = {
    machineSetup: new RouteInfo(AppRoutes.clientToolsRoot, "machine-setup", {
      title: "Machine Setup",
      roleView: UserRoles.ClientTools_MachineBackups_View,
    }),
    download: new RouteInfo(AppRoutes.clientToolsRoot, "download", {
      title: "Install Client Tools",
      roleView: UserRoles.ClientTools_Download_View,
    }),
  };
  // #endregion

  // #region Schedule One Module
  public static scheduleOneRoot = new RouteInfo(AppRoutes.root, "schedule-one", {
    title: "Schedule One",
    roleArea: UserRoles.ScheduleOne,
  });
  public static scheduleOne = {
    dailyDivisionalSchedule: new RouteInfo(AppRoutes.scheduleOneRoot, "daily-divisional-schedule", {
      title: "Daily Divisional Schedule",
      roleView: UserRoles.ScheduleOne_DailyDivisionalSchedule_View,
    }),
    machineSchedule: new RouteInfo<[number?, number?, number?, string?]>(
      AppRoutes.scheduleOneRoot,
      "machine-schedule/:?divisionId/:?cellId/:?machineId/:?date",
      {
        title: "Machine Schedule",
        roleView: UserRoles.ScheduleOne_MachineSchedule_View,
        defaultPathParams: [0, 0, -1, ""],
      }
    ),
  };
  // #endregion

  // #region Shipping Module
  public static shippingRoot = new RouteInfo(AppRoutes.root, "shipping", {
    title: "Shipping",
    roleArea: UserRoles.Shipping,
  });
  public static shipping = {
    qb2023ShipmentImportStatus: new RouteInfo(AppRoutes.shippingRoot, "qb2023-shipment-import-status", {
      title: "QB2023 Shipment Import Status",
      roleView: UserRoles.Shipping_QB2023ShipmentImportStatus_View,
    }),
    warehouseShipping: new RouteInfo<[string?, number?, number?, ShipmentStatus?, boolean?]>(
      AppRoutes.shippingRoot,
      "warehouse-shipping/:?searchTerm/:?warehouseCellId/:?shipToCellId/:?status/:?showInactive",
      {
        title: "Warehouse Shipping",
        roleView: UserRoles.Shipping_WarehouseShipping_View,
        defaultPathParams: ["", -1, -1, ShipmentStatus.Active, false],
      }
    ),
    warehouseShipment: new RouteInfo<[number?, string?, number?, number?, ShipmentStatus?, boolean?]>(
      AppRoutes.shippingRoot,
      "warehouse-shipment/:?id/:?searchTerm/:?warehouseCellId/:?shipToCellId/:?status/:?showInactive",
      {
        title: "Warehouse Shipment",
        roleArea: UserRoles.Shipping_WarehouseShipping_View,
        defaultPathParams: [-1, "", -1, -1, ShipmentStatus.Active, false],
      }
    ),
    warehouseReturns: new RouteInfo(AppRoutes.shippingRoot, "warehouse-returns", {
      title: "Warehouse Returns",
      roleView: UserRoles.Shipping_WarehouseReturns_View,
    }),
    warehouseJournalReport: new RouteInfo(AppRoutes.shippingRoot, "warehouse-journal-report", {
      title: "Warehouse Journal Report",
      roleView: UserRoles.Shipping_WarehouseJournalReport_View,
    }),
  };
  // #endregion

  // #region Support Service Metrics Module
  public static supportServiceMetricsRoot = new RouteInfo(AppRoutes.root, "support-service-metrics", {
    title: "Support Service Metrics",
    roleArea: UserRoles.SupportServiceMetrics,
  });
  public static supportServiceMetrics = {
    manageQuestions: new RouteInfo(AppRoutes.supportServiceMetricsRoot, "manage-questions", {
      title: "Manage Questions",
      roleView: UserRoles.SupportServiceMetrics_ManageQuestions_View,
    }),
    incidentEntry: new RouteInfo(AppRoutes.supportServiceMetricsRoot, "incident-entry", {
      title: "Incident Entry",
      roleView: UserRoles.SupportServiceMetrics_IncidentEntry_View,
    }),
    incidentReport: new RouteInfo(AppRoutes.supportServiceMetricsRoot, "incident-report", {
      title: "Incident Report",
      roleView: UserRoles.SupportServiceMetrics_IncidentReport_View,
    }),
    incidentsDashboard: new RouteInfo(AppRoutes.supportServiceMetricsRoot, "incidents-dashboard", {
      title: "Incidents Dashboard",
      roleView: UserRoles.SupportServiceMetrics_IncidentsDashboard_View,
    }),
  };
  // #endregion

  // #region CDS Module
  public static cdsRoot = new RouteInfo(AppRoutes.root, "cds", {
    title: "CDS",
    roleArea: UserRoles.CDS,
  });
  public static cds = {
    cdsDocuments: new RouteInfo(AppRoutes.cdsRoot, "cds-documents", {
      title: "CDS Documents",
      roleView: UserRoles.CDS_CdsDocuments_View,
    }),
    cdsSpecifications: new RouteInfo(AppRoutes.cdsRoot, "cds-specifications", {
      title: "CDS Specifications",
      roleView: UserRoles.CDS_CdsSpecifications_View,
    }),
  };
  // #endregion

  // #region CPR Module
  public static cprRoot = new RouteInfo(AppRoutes.root, "cpr", {
    title: "CPR",
    roleArea: UserRoles.CPR,
  });
  public static cpr = {
    manageCprMatrix: new RouteInfo(AppRoutes.cprRoot, "manage-cpr-matrix", {
      title: "Manage CPR Matrix",
      roleView: UserRoles.CPR_ManageCprMatrix_View,
    }),
    cprMatrix: new RouteInfo(AppRoutes.cprRoot, "cpr-matrix", {
      title: "CPR Matrix",
      roleView: UserRoles.CPR_CprMatrix_View,
    }),
  };
  // #endregion

  // #region QSD Module
  public static qsdRoot = new RouteInfo(AppRoutes.root, "qsd", {
    title: "QSD",
    roleArea: UserRoles.QSD,
  });
  public static qsd = {
    qsd: new RouteInfo(AppRoutes.qsdRoot, "qsd", {
      title: "QSD",
      roleView: UserRoles.QSD_QSD_View,
    }),
    qsdReport: new RouteInfo(AppRoutes.qsdRoot, "qsd-report", {
      title: "QSD Report",
      roleView: UserRoles.QSD_QSDReport_View,
    }),
    rmaLog: new RouteInfo(AppRoutes.qsdRoot, "rma-log", {
      title: "RMA Log",
      roleView: UserRoles.QSD_RMALog_View,
    }),
  };
  // #endregion

  // #region Quality Audit Module
  public static qualityAuditsRoot = new RouteInfo(AppRoutes.root, "quality-audits", {
    title: "Quality Audits",
    roleArea: UserRoles.QualityAudits,
  });
  public static qualityAudits = {
    overview: new RouteInfo(AppRoutes.qualityAuditsRoot, "overview", {
      title: "Overview",
      roleView: UserRoles.QualityAudits_Overview_View,
    }),
    questionSetup: new RouteInfo(AppRoutes.qualityAuditsRoot, "question-setup", {
      title: "Question Setup",
      roleView: UserRoles.QualityAudits_QuestionSetup_View,
    }),
    scheduling: new RouteInfo(AppRoutes.qualityAuditsRoot, "scheduling", {
      title: "Scheduling",
      roleView: UserRoles.QualityAudits_Scheduling_View,
    }),
    audit: new RouteInfo(AppRoutes.qualityAuditsRoot, "audit", {
      title: "Audit",
      roleView: UserRoles.QualityAudits_Audit_View,
    }),
  };
  // #endregion

  // #region Fox Module
  public static foxRoot = new RouteInfo(AppRoutes.root, "fox", {
    title: "Fox",
    roleArea: UserRoles.Fox,
  });
  public static fox = {
    profitabilityByPart: new RouteInfo(AppRoutes.foxRoot, "profitability-by-part", {
      title: "Profitability By Part",
      roleView: UserRoles.Fox_ProfitabilityByPart_View,
    }),
    qbLaborJournalEntry: new RouteInfo(AppRoutes.foxRoot, "qb-labor-journal-entry", {
      title: "QB Labor Journal Entry",
      roleView: UserRoles.Fox_QBLaborJournalEntry_View,
    }),
    mnageAccountToCellRelationships: new RouteInfo(AppRoutes.foxRoot, "manage-account-to-cell-relationships", {
      title: "Manage Account to Cell Relationships",
      roleView: UserRoles.Fox_ManageAccountToCellRelationships_View,
    }),
  };
  // #endregion

  // #region MRP Module
  public static mrpRoot = new RouteInfo(AppRoutes.root, "mrp", {
    title: "MRP",
    roleArea: UserRoles.MRP,
  });
  public static mrp = {
    firmOrderOverageReport: new RouteInfo(AppRoutes.mrpRoot, "firm-order-overage-report", {
      title: "Firm Order Overage Report",
      roleView: UserRoles.MRP_FirmOrderOverageReport_View,
    }),
    negativeExpectedInventoryReport: new RouteInfo(AppRoutes.mrpRoot, "negative-expected-inventory-report", {
      title: "Negative Expected Inventory Report",
      roleView: UserRoles.MRP_NegativeExpectedInventoryReport_View,
    }),
  };
  // #endregion
}

export type RouteInfoData<PathParams extends Primitives[] = [], QueryParams extends {} = {}> = FdRouteInfoData<
  UserRoles,
  PathParams,
  QueryParams
>;

export type PathParamsForRoute<T> = T extends RouteInfo<infer PathParams> ? PathParams : never;

export type QueryParamsForRoute<T> = T extends RouteInfo<never, infer QueryParams> ? QueryParams : never;
