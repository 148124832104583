<div mat-dialog-title>
  <h2>{{ title }}</h2>
</div>
<mat-dialog-content>
  <div class="row" *ngIf="!isLoading">
    <div class="col-12 col-sm-6">
      <div *ngFor="let link of application.viewList; let idx = index">
        <div class="d-flex align-items-center">
          <ng-container *ngIf="!link.isDivider; else divider">
            <button mat-icon-button (click)="toggleBookmark(link)">
              <mat-icon>{{ link.isFavorite ? "bookmark" : "bookmark_outlined" }}</mat-icon>
            </button>
            <a
              *ngIf="link.absoluteUrl?.startsWith('/'); else externalLink"
              [class.current]="link.absoluteUrl === currentPath"
              mat-button
              (click)="close()"
              [routerLink]="link.absoluteUrl.toLowerCase()"
              [autofocus]="link.absoluteUrl === currentPath">
              {{ link.viewName }}
            </a>
          </ng-container>
        </div>

        <ng-template #externalLink>
          <a [href]="link.absoluteUrl" mat-button target="_blank">
            {{ link.viewName }}
          </a>
        </ng-template>

        <ng-template #divider>
          <span class="text-muted menu-section" [class.first-menu]="!idx">{{ link.viewName }}</span>
        </ng-template>
      </div>
    </div>
    <div class="col-12 col-sm-6 pt-2 pt-sm-0">
      <app-application-owner [application]="application" />
      <!--div class="mt-4">
            <h3>Custom Favorites</h3>
        </div-->
    </div>
  </div>
  <mat-spinner *ngIf="isLoading" class="mx-auto" [diameter]="50" />
</mat-dialog-content>
