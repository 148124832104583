import { Component, inject } from "@angular/core";
import { SwUpdate, VersionReadyEvent } from "@angular/service-worker";
import { FdRouterService, GlobalLoadingIndicatorService, LocalStoreManager } from "@fd/core";
import { BaseComponent } from "./base.component";
import { OnlineStatusService } from "../modules/shared/services/online-status.service";
import { IsTitleService } from "../modules/shared/services/is-title.service";
import { filter } from "rxjs";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent extends BaseComponent {
  private readonly appTitleService = inject(IsTitleService);
  private readonly globalLoadingIndicatorService = inject(GlobalLoadingIndicatorService);
  private readonly onlineStatusService = inject(OnlineStatusService);
  protected readonly storageManager = inject(LocalStoreManager);
  readonly fdRouterService = inject(FdRouterService);

  showGlobalLoadingIndicator = false;
  isLoadingLazyModule = false;
  appTitle = document.title;
  notificationCount = 0;
  hasHelp = false;
  showSidebar = false;

  constructor(private serviceWorker: SwUpdate) {
    super();

    // if there is a new version, reload the app
    this.serviceWorker.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
    ).subscribe(() => {
      this.serviceWorker.activateUpdate().then(() => document.location.reload());
    });

    if (this.serviceWorker.isEnabled) {
      this.serviceWorker.checkForUpdate();
    }

    this.storageManager.initializeStorageSyncListener();

    this.appTitleService.appName = this.appTitle;

    // subscribe to routing events to trigger a busy spinner on loading a lazy module
    this.fdRouterService.routeConfigLoadStart$.subscribe(() => (this.isLoadingLazyModule = true));
    this.fdRouterService.routeConfigLoadEnd$.subscribe(() => (this.isLoadingLazyModule = false));
    this.fdRouterService.navigationCancel$.subscribe(() => (this.isLoadingLazyModule = false));
    this.fdRouterService.navigationError$.subscribe(() => (this.isLoadingLazyModule = false));

    // dynamically update the menu title on navigation end
    this.fdRouterService.navigationEnd$.subscribe(navigationEnd => {
      this.showSidebar = navigationEnd.url.startsWith("/framework");
    });

    // subscribe to loading events to trigger a busy progress bar
    this.globalLoadingIndicatorService.isLoading$.subscribe(isLoading =>
      setTimeout(() => (this.showGlobalLoadingIndicator = isLoading))
    );

    // Begin tracking network connectivity status
    this.onlineStatusService.initialize();
  }
}
