/**
 *  Client version of Repository/Repositories/Common/Entity/Entitytype.cs
 *  TODO: Code generation - for now, we will have to manually update both files so that they match
 */
export enum EntityType {
  Undefined = -1,
  Innotec = 0,
  User = 1,
  Cell = 2,
  Division = 3,
  CR = 4,
  CellResponsibility = 5,
  Operation = 5,
  Machine = 6,
  Part = 7,
  PartFamily = 8,
  DRMentor = 11,
  CRMentor = 12,
  Customer = 20,
  Product = 21,
  Region = 22,
  Station = 23,
  SubStation = 24,
  ManualProcess = 25,
  Account = 29,
  Finance = 29,
  SuperCell = 35,
  FocusFactory = 36,
  DivisionGroup = 39,
  Building = 40,
  BOT_RESPONSIBILITY = 41,
  ONEPP_RESPONSIBILITY = 42,
  LaunchKPI = 43,
  Employee = 202,
  Company = 203,
  Location = 204,
  Origin = 301,
  ScrapIssue = 302,
  DowntimeIssue = 303,
  DR = 1004,
  Playlist = -2,
  PURCHASED_MATERIAL = 44,
  CUSTOMER_EXTERNAL_PART = 28,
  Job = -3,
  Supplier = -4,
  Commodity = -5,
  SubCommodidty = -6,
  PurchaseOrder = -7,
  FoxCapxProject = 400,
  FoxPassThroughProject = 401,
  SupplierDevelopmentScoreCard = 500,
  BoardFreeManageGuidelines = 600,
  BoardFreeGuidelineSuggestions = 700,
  BoardFreeManageSubsection = 701,
  EntityManagerCustomerPart = 702,
  BoardFreeAuditingParts = 703,
  ProjectAttachment = 704,
}
