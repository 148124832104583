<div class="page-container">
  <h1 class="pageHeader">Welcome to InnoSuite 2022!</h1>

  Tips:
  <ul>
    <li class="d-flex align-items-center">
      Use the Search <mat-icon class="mx-2">search</mat-icon> to switch your app.
    </li>
    <li class="d-flex align-items-center">
      Use the Main Menu <mat-icon class="mx-2">menu</mat-icon> to navigate between pages of your app.
    </li>
  </ul>
</div>
