import { NgModule } from "@angular/core";
import { DataFooterComponent } from "./components/data-footer/data-footer.component";
import { SearchFormFieldComponent } from "./components/search-form-field/search-form-field.component";
import { SearchInputComponent } from "./components/search-input/search-input.component";
import {
  AutofocusDirective,
  ContainerClassNamePipe,
  DirtyFormDirective,
  FdCardDefDirective,
  FdCardsComponent,
  FdLayoutBreadcrumbsComponent,
  FdLayoutComponent,
  FdLayoutContentComponent,
  FdLayoutDebugComponent,
  FdLayoutFixedFooterComponent,
  FdLayoutHeaderPrimaryComponent,
  FdLayoutHeaderSecondaryComponent,
  FdLayoutSidebarComponent,
  FdLayoutSidenavComponent,
  FdLayoutTitleComponent,
  FdLayoutToggleDirective,
  FdSortSelectComponent,
  FdSpinnerComponent,
  FdViewLinksComponent,
  MatPaginatorDirective,
  TableExporterComponent,
  TypeSafeMatCellDef,
} from "@fd/core";
import { AutocompleteComponent, AutocompleteDirective } from "./components/autocomplete/autocomplete.component";

const global = [
  AutofocusDirective,
  DirtyFormDirective,
  MatPaginatorDirective,
  TypeSafeMatCellDef,
  FdLayoutComponent,
  FdLayoutBreadcrumbsComponent,
  FdLayoutFixedFooterComponent,
  FdLayoutDebugComponent,
  FdLayoutSidenavComponent,
  FdLayoutSidebarComponent,
  FdLayoutTitleComponent,
  FdLayoutHeaderPrimaryComponent,
  FdLayoutHeaderSecondaryComponent,
  FdLayoutContentComponent,
  FdSortSelectComponent,
  FdViewLinksComponent,
  DataFooterComponent,
  FdCardsComponent,
  FdCardDefDirective,
  FdLayoutToggleDirective,
  TableExporterComponent,
  SearchFormFieldComponent,
  SearchInputComponent,
  ContainerClassNamePipe,
  FdSpinnerComponent,
  AutocompleteComponent,
  AutocompleteDirective,
];

/**
 * All directives, components, and pipes that should be applied globally because they either:
 *   a) Should automatically augment built-in HTML elements always.
 *   b) Are used all the time so that it makes to just include them automatically.
 *
 * This module still needs to be imported in each individual module or standalone component for it to take effect.
 *
 * Any components that may be used, but don't fit the above description should not be included here.
 */
@NgModule({
  imports: global,
  exports: global,
})
export class SharedModule {}
