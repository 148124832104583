import { MatFormFieldDefaultOptions } from "@angular/material/form-field";
import { MatPaginatorDefaultOptions } from "@angular/material/paginator";
import { PasswordValidation } from "@rxweb/reactive-form-validators/models";
import { AppEnvironment, IEnvironment } from "@fd/core";

export abstract class IEnvironments implements IEnvironment {
  configuration: AppEnvironment;
  testingUrl: string;
  productionUrl: string;
  localServerUrl: string;
  baseUrl: string;
  passwordRequirements: PasswordValidation;
  passwordRequirementMessages: string[];
  onlineStatusNotifications: {
    enabled: boolean;
    connectionRestored: string;
    connectionLost: string;
  };
  matPaginatorDefaultOptions: MatPaginatorDefaultOptions;
  matFormFieldDefaultOptions: MatFormFieldDefaultOptions;
  siteUrls: ISiteUrls;
}

export interface ISiteUrls {
  isUrl: string;
  mobileUrl: string;
  privateUrl: string;
  cdnUrl: string;
  angularUrl: string;
  rootAssetUrl: string;
}
export const defaultEnvironment: IEnvironments = {
  configuration: AppEnvironment.Development,
  baseUrl: null, // Change this to the address of your backend API if different from frontend address
  passwordRequirements: {
    minLength: 6,
    digit: true,
    upperCase: true,
    lowerCase: true,
  },
  passwordRequirementMessages: [
    "Must be at least 6 characters in length.",
    "Must contain an uppercase letter.",
    "Must contain a lowercase letter.",
    "Must contain a number.",
  ],
  onlineStatusNotifications: {
    enabled: true, // When true, notifications will be shown when network connectivity is lost or restored
    connectionRestored: "You are now back online!",
    connectionLost: "You are offline.",
  },
  matPaginatorDefaultOptions: {
    formFieldAppearance: "fill",
    hidePageSize: false,
    pageSize: 25,
    pageSizeOptions: [5, 25, 100],
    showFirstLastButtons: true,
  },
  matFormFieldDefaultOptions: {
    appearance: "fill",
  },
  siteUrls: {
    isUrl: "https://isdev.innotecgroup.com/",
    mobileUrl: "https://mobiledev.innotecgroup.com/",
    privateUrl: "https://privatedev.innotecgroup.com/",
    cdnUrl: "https://cdndev.innotecgroup.com/",
    angularUrl: "https://isdev5.innotecgroup.com/",
    rootAssetUrl: "https://isassetsdev.innotecgroup.com/",
  },
  testingUrl: "",
  productionUrl: "",
  localServerUrl: require("../../../Properties/launchSettings.json").profiles.Development.applicationUrl,
};
