import { Component, Input } from "@angular/core";
import { ApplicationInterface } from "src/app/modules/shared/models/common/appliaction.model";

@Component({
  selector: "app-application-owner",
  templateUrl: "./application-owner.component.html",
  styleUrl: "./application-owner.component.scss",
})
export class ApplicationOwnerComponent {
  @Input() application: ApplicationInterface;
  get mailto() {
    return `mailto:${this.application.applicationEmail};?subject=${this.application.name}`;
  }
}
