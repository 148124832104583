import { Injectable } from "@angular/core";
import { ControllerBase } from "@fd/core";
import { Observable } from "rxjs";
import { UserListInterface } from "src/app/modules/shared/models/common/user-list.model";
import { UserType } from "../../../enums/user-type.enum";

@Injectable({ providedIn: "root" })
export class UserListApiService extends ControllerBase<UserListInterface> {
  constructor() {
    super("Common/UserList");
  }

  //#region super implementation
  //get() {
  //  return super.$get();
  //}
  //
  //getById(id: string) {
  //  return super.$getById(id);
  //}
  //
  //post(model: UserListInterface) {
  //  return super.$post(model);
  //}
  //
  //put(model: UserListInterface) {
  //  return super.$put(model);
  //}
  //
  //delete(id: string) {
  //  return super.$delete(id);
  //}

  count(searchTerm: string = "") {
    return super._count({ query: { searchTerm } });
  }
  //#endregion

  search(
    searchTerm: string,
    userType: UserType = null,
    top: number = null,
    usePayrollSystem: boolean = null
  ): Observable<UserListInterface[]> {
    return super._getBySearch<UserListInterface[]>(searchTerm, { query: { userType, top, usePayrollSystem } });
  }
}
