import { Component, OnInit } from "@angular/core";
import { FdRoute, FdRouterService } from "@fd/core";
import { forkJoin } from "rxjs";
import { environment } from "src/environments/environment";
import { ApplicationInterface } from "src/app/modules/shared/models/common/appliaction.model";
import { ApplicationHelpInterface } from "src/app/modules/shared/models/common/application-help.model";
import { ApplicationApiService } from "src/app/modules/shared/services/api/Common/application-api.service";
import { ApplicationHelpApiService } from "src/app/modules/shared/services/api/Common/application-help-api.service";
import { BaseComponent } from "../../base.component";
import { Router } from "@angular/router";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-help-dialog",
  templateUrl: "./help-dialog.component.html",
  styleUrl: "./help-dialog.component.scss",
})
export class HelpDialogComponent extends BaseComponent implements OnInit {

  static readonly hashId = "help";
  isLoading = true;
  application: ApplicationInterface;
  applicationHelp: ApplicationHelpInterface;
  selectedIndex: number;
  mailto: any;
  editeHelpURL:string;

  constructor(
    private applicationHelpApiService: ApplicationHelpApiService,
    private applicationApiService: ApplicationApiService,
    public fdRouterService: FdRouterService,
    private router: Router,
    private dialogRef: MatDialogRef<HelpDialogComponent>
  ) {
    super();
    this.setHash(false);
  }

  ngOnInit(): void {
    const parts = this.router.url.split("#")[0].split("/");
    forkJoin({
      applicationHelp: this.applicationHelpApiService.get(parts[1], parts[2]),
      application: this.applicationApiService.get(parts[1]),
    }).subscribe(data => {
      this.applicationHelp = data.applicationHelp;
      this.application = data.application;
      this.selectedIndex = data.applicationHelp.viewHelpText ? 1 : 0;
      this.isLoading = false;
      this.mailto = `mailto:${this.application.applicationEmail};?subject=${this.application.name}`;  
      this.editeHelpURL = `${environment.siteUrls.isUrl}SiteTools/ApplicationHelp?applicationID=${this.applicationHelp.applicationId}&viewID=${this.applicationHelp.viewId}`
    });
  }

  private setHash(replaceState: boolean) {
    const pathParts: [string, string] = [HelpDialogComponent.hashId, ""];
    this.fdRouterService.setFragment(new FdRoute({ pathParts }), replaceState);
  }

  closeDialog() {   
      this.dialogRef.close();    
  }
}
