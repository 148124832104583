/**
 *  Client version of Repository/Infrastructure/UserRoles.cs
 *  TODO: Code generation - for now, we will have to manually update both files so that they match
 */
export enum UserRoles {
  System = "System",

  Admin = "Admin",
  Admin_Users_View = "Admin_Users_View",
  Admin_Users_Edit = "Admin_Users_Edit",
  Admin_SecurityGroups_View = "Admin_SecurityGroups_View",
  Admin_SecurityGroups_Edit = "Admin_SecurityGroups_Edit",
  Admin_ImpersonateUser = "Admin_ImpersonateUser",

  Payroll = "Payroll",
  Payroll_TimeOffRequestAdmin = "Payroll_TimeOffRequestAdmin",
  Payroll_TeamSupervisor = "Payroll_TeamSupervisor",
  Payroll_PTOTracked = "Payroll_PTOTracked",
  Payroll_UsePayrollSystem = "Payroll_UsePayrollSystem",

  Payroll_ManageIncentives_View = "Payroll_ManageIncentives_View",
  Payroll_ManageIncentives_EfficiencyBonus = "Payroll_ManageIncentives_EfficiencyBonus",
  Payroll_ManageIncentives_PieceRateIncentive = "Payroll_ManageIncentives_PieceRateIncentive",
  Payroll_ManageIncentives_OffshiftPremium = "Payroll_ManageIncentives_OffshiftPremium",
  Payroll_TimecardApproval_View = "Payroll_TimecardApproval_View",
  Payroll_TimecardApproval_Edit = "Payroll_TimecardApproval_Edit",
  Payroll_TimecardApproval_AllEmployeeData = "Payroll_TimecardApproval_AllEmployeeData",
  Payroll_ManageGroups_View = "Payroll_ManageGroups_View",
  Payroll_ManageGroups_Edit = "Payroll_ManageGroups_Edit",
  Payroll_ManageGroups_IncludeInSupervisorList = "Payroll_ManageGroups_IncludeInSupervisorList",
  Payroll_PayReport_View = "Payroll_PayReport_View",
  Payroll_PayReport_Edit = "Payroll_PayReport_Edit",
  Payroll_PayReport_CanLockWeek = "Payroll_PayReport_CanLockWeek",
  Payroll_PayDetailsReport_View = "Payroll_PayDetailsReport_View",
  Payroll_PayDetailsReport_Edit = "Payroll_PayDetailsReport_Edit",
  Payroll_PayDetailsReport_AllEmployeeData = "Payroll_PayDetailsReport_AllEmployeeData",
  Payroll_ManageTimeCards_View = "Payroll_ManageTimeCards_View",
  Payroll_ManageTimeCards_Edit = "Payroll_ManageTimeCards_Edit",
  Payroll_ManageTimeCards_AllEmployeeData = "Payroll_ManageTimeCards_AllEmployeeData",
  Payroll_OffshiftPremiumEarnings_View = "Payroll_OffshiftPremiumEarnings_View",
  Payroll_OffshiftPremiumEarnings_Edit = "Payroll_OffshiftPremiumEarnings_Edit",
  Payroll_PayDetailsExport_View = "Payroll_PayDetailsExport_View",
  Payroll_PayDetailsExport_Edit = "Payroll_PayDetailsExport_Edit",

  TimeOff = "TimeOff",
  TimeOff_TimeOffBalances_View = "TimeOff_TimeOffBalances_View",
  TimeOff_TimeOffBalances_Edit = "TimeOff_TimeOffBalances_Edit",
  TimeOff_TimeOffRequests_View = "TimeOff_TimeOffRequests_View",
  TimeOff_TimeOffRequests_Edit = "TimeOff_TimeOffRequests_Edit",
  TimeOff_ManageTimeOffRequests_View = "TimeOff_ManageTimeOffRequests_View",
  TimeOff_ManageTimeOffRequests_Edit = "TimeOff_ManageTimeOffRequests_Edit",
  TimeOff_ManageTimeOffRequests_AllEmployeeData = "TimeOff_ManageTimeOffRequests_AllEmployeeData",

  ShirtSize = "ShirtSize",
  ShirtSize_Report_View = "ShirtSize_Report_View",
  ShirtSize_Report_Edit = "ShirtSize_Report_Edit",

  MachineBackup = "MachineBackup",
  MachineBackup_MachineBackups_View = "MachineBackup_MachineBackups_View",
  MachineBackup_MachineBackups_Edit = "MachineBackup_MachineBackups_Edit",

  Team = "Team",
  Team_CRMatrix_View = "Team_CRMatrix_View",
  Team_CRMatrix_Edit = "Team_CRMatrix_Edit",
  Team_Teammates_View = "Team_Teammates_View",
  Team_Teammates_Edit = "Team_Teammates_Edit",
  Team_RedbullCellStationOwnerMatrix_View = "Team_RedbullCellStationOwnerMatrix_View",
  Team_RedbullCellStationOwnerMatrix_Edit = "Team_RedbullCellStationOwnerMatrix_Edit",
  Team_FindUserRoles_View = "Team_FindUserRoles_View",
  Team_FindUserRoles_Edit = "Team_FindUserRoles_Edit",

  ClientTools = "ClientTools",
  ClientTools_MachineBackups_View = "ClientTools_MachineBackups_View",
  ClientTools_MachineBackups_Edit = "ClientTools_MachineBackups_Edit",
  ClientTools_Download_View = "ClientTools_Download_View",
  ClientTools_Download_Edit = "ClientTools_Download_Edit",

  ScheduleOne = "ScheduleOne",
  ScheduleOne_DailyDivisionalSchedule_View = "ScheduleOne_DailyDivisionalSchedule_View",
  ScheduleOne_DailyDivisionalSchedule_Edit = "ScheduleOne_DailyDivisionalSchedule_Edit",
  ScheduleOne_MachineSchedule_View = "ScheduleOne_MachineSchedule_View",
  ScheduleOne_MachineSchedule_Edit = "ScheduleOne_MachineSchedule_Edit",

  Shipping = "Shipping",
  Shipping_QB2023ShipmentImportStatus_View = "Shipping_QB2023ShipmentImportStatus_View",
  Shipping_QB2023ShipmentImportStatus_Edit = "Shipping_QB2023ShipmentImportStatus_Edit",
  Shipping_WarehouseShipping_View = "Shipping_WarehouseShipping_View",
  Shipping_WarehouseShipping_Edit = "Shipping_WarehouseShipping_Edit",
  Shipping_WarehouseReturns_View = "Shipping_WarehouseReturns_View",
  Shipping_WarehouseReturns_Edit = "Shipping_WarehouseReturns_Edit",
  Shipping_WarehouseJournalReport_View = "Shipping_WarehouseJournalReport_View",
  Shipping_WarehouseJournalReport_Edit = "Shipping_WarehouseJournalReport_Edit",

  SupportServiceMetrics = "SupportServiceMetrics",
  SupportServiceMetrics_ManageQuestions_View = "SupportServiceMetrics_ManageQuestions_View",
  SupportServiceMetrics_ManageQuestions_Edit = "SupportServiceMetrics_ManageQuestions_Edit",
  SupportServiceMetrics_IncidentEntry_View = "SupportServiceMetrics_IncidentEntry_View",
  SupportServiceMetrics_IncidentEntry_Edit = "SupportServiceMetrics_IncidentEntry_Edit",
  SupportServiceMetrics_IncidentReport_View = "SupportServiceMetrics_IncidentReport_View",
  SupportServiceMetrics_IncidentReport_Edit = "SupportServiceMetrics_IncidentReport_Edit",
  SupportServiceMetrics_IncidentsDashboard_View = "SupportServiceMetrics_IncidentsDashboard_View",

  CDS = "CDS",
  CDS_QualityTeamCR = "CDS_QualityTeamCR",
  CDS_QualityTeamDR = "CDS_QualityTeamDR",
  CDS_CellQualityTeamMember = "CDS_CellQualityTeamMember",
  CDS_CdsDocuments_View = "CDS_CdsDocuments_View",
  CDS_CdsDocuments_Edit = "CDS_CdsDocuments_Edit",
  CDS_CdsSpecifications_View = "CDS_CdsSpecifications_View",
  CDS_CdsSpecifications_Edit = "CDS_CdsSpecifications_Edit",

  CPR = "CPR",
  CPR_ManageCprMatrix_View = "CPR_ManageCprMatrix_View",
  CPR_ManageCprMatrix_Edit = "CPR_ManageCprMatrix_Edit",
  CPR_CprMatrix_View = "CPR_CprMatrix_View",
  CPR_CprMatrix_Edit = "CPR_CprMatrix_Edit",

  QSD = "QSD",
  QSD_QSD_View = "QSD_QSD_View",
  QSD_QSD_Edit = "QSD_QSD_Edit",
  QSD_QSDReport_View = "QSD_QSDReport_View",
  QSD_QSDReport_Edit = "QSD_QSDReport_Edit",
  QSD_RMALog_View = "QSD_RMALog_View",
  QSD_RMALog_Edit = "QSD_RMALog_Edit",

  QualityAudits = "QualityAudits",
  QualityAudits_Overview_View = "QualityAudits_Overview_View",
  QualityAudits_Overview_Edit = "QualityAudits_Overview_Edit",
  QualityAudits_QuestionSetup_View = "QualityAudits_QuestionSetup_View",
  QualityAudits_QuestionSetup_Edit = "QualityAudits_QuestionSetup_Edit",
  QualityAudits_Scheduling_View = "QualityAudits_Scheduling_View",
  QualityAudits_Scheduling_Edit = "QualityAudits_Scheduling_Edit",
  QualityAudits_Audit_View = "QualityAudits_Audit_View",
  QualityAudits_Audit_Edit = "QualityAudits_Audit_Edit",

  Fox = "Fox",
  Fox_ProfitabilityByPart_View = "Fox_ProfitabilityByPart_View",
  Fox_ProfitabilityByPart_Edit = "Fox_ProfitabilityByPart_Edit",
  Fox_QBLaborJournalEntry_View = "Fox_QBLaborJournalEntry_View",
  Fox_QBLaborJournalEntry_Edit = "Fox_QBLaborJournalEntry_Edit",
  Fox_ManageAccountToCellRelationships_View = "Fox_ManageAccountToCellRelationships_View",
  Fox_ManageAccountToCellRelationships_Edit = "Fox_ManageAccountToCellRelationships_Edit",

  MRP = "MRP",
  MRP_FirmOrderOverageReport_View = "MRP_FirmOrderOverageReport_View",
  MRP_FirmOrderOverageReport_Edit = "MRP_FirmOrderOverageReport_Edit",
  MRP_NegativeExpectedInventoryReport_View = "MRP_NegativeExpectedInventoryReport_View",
  MRP_NegativeExpectedInventoryReport_Edit = "MRP_NegativeExpectedInventoryReport_Edit",
}
