import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { TableExporterComponent } from "@fd/core";

@Component({
  selector: "fd-data-footer",
  standalone: true,
  imports: [CommonModule, MatPaginatorModule, TableExporterComponent],
  templateUrl: "./data-footer.component.html",
  styleUrl: "./data-footer.component.scss",
})
export class DataFooterComponent {
  @Input() elevated = false;
}
