import { Injectable } from "@angular/core";
import { UserGroupAccessDetail, GroupInterface } from "../models/group.model";
import { ControllerBase } from "@fd/core";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class GroupApiService extends ControllerBase<GroupInterface> {
  constructor() {
    super("Security/Group");
  }

  //#region super implementation
  get(): Observable<GroupInterface[]> {
    return super.$get<GroupInterface[]>();
  }

  getById(id: string): Observable<GroupInterface> {
    return super.$getById<GroupInterface>(id);
  }

  post(model: GroupInterface) {
    return super.$post<string>(model);
  }

  put(model: GroupInterface) {
    return super.$put(model);
  }

  delete(id: string) {
    return super.$delete(id);
  }

  getAccessDetails(group: string){

    // add token and do other preparations
    return super._get<UserGroupAccessDetail[]>({url: `AccessDetails/${group}`});
  }
  //#endregion
}
