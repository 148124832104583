import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BackdropClass, FdRoute, FdRouterService } from "@fd/core";
import { Router } from "@angular/router";
import { forkJoin, Observable, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from "rxjs/operators";
import { EntityType } from "src/app/modules/shared/enums/entity-type.enum";
import { ApplicationInterface } from "src/app/modules/shared/models/common/appliaction.model";
import { UserFavoriteInterface } from "src/app/modules/shared/models/common/user-favorite.model";
import { ApplicationApiService } from "src/app/modules/shared/services/api/Common/application-api.service";
import { EntityApiService } from "src/app/modules/shared/services/api/Common/entity-api.service";
import { UserFavoriteApiService } from "src/app/modules/shared/services/api/Common/user-favorite-api.service";
import { UserListApiService } from "src/app/modules/shared/services/api/Common/user-list-api.service";
import { MediaApiService } from "src/app/modules/shared/services/api/Common/media-api.service";
import { environment } from "src/environments/environment";
import { BaseDialogComponent } from "../../base-dialog.component";

type Category = "Favorites" | "Apps" | "Team" | "Documents" | "CR" | "Entity" | "All";
type CategoryCount = { category: Category; count?: number };
type CategoryResult = {
  category: Category;
  results$: Observable<any[]>;
  results?: any[];
  template: TemplateRef<any>;
  totalCount: () => number;
  resultsCount: () => number;
};

@Component({
  selector: "app-search-dialog",
  templateUrl: "./search-dialog.component.html",
  styleUrl: "./search-dialog.component.scss",
})
export class SearchDialogComponent extends BaseDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("searchInput") searchInput: ElementRef<HTMLInputElement>;
  @ViewChild("entityTemplate") entityTemplate: TemplateRef<any>;
  @ViewChild("crTemplate") crTemplate: TemplateRef<any>;
  @ViewChild("teamTemplate") teamTemplate: TemplateRef<any>;
  @ViewChild("favoritesTemplate") favoritesTemplate: TemplateRef<any>;
  @ViewChild("appsTemplate") appsTemplate: TemplateRef<any>;
  @ViewChild("documentsTemplate") documentsTemplate: TemplateRef<any>;

  static readonly hashId = "search";
  static readonly crTypes = [EntityType.CR, EntityType.CRMentor];
  static readonly entityTypes = [EntityType.Division, EntityType.Cell, EntityType.FocusFactory, EntityType.DRMentor];

  readonly summaryItemCount = 4;
  static readonly detailsItemCount = 20;
  override environment = environment;

  initializing = true;
  activeCategory: Category = "All";
  searchFormControl = new FormControl(this.data.route?.pathParts[1] || "");
  searchFormValueChanges$: Observable<string> = this.searchFormControl.valueChanges.pipe(
    startWith(this.searchFormControl.value || ""),
    debounceTime(500),
    distinctUntilChanged()
  );

  categoryCounts: CategoryCount[];

  categoryTops = this.defaultCategoryTops();
  categoryResults: CategoryResult[];

  private searchSubscribe: Subscription;

  protected get backdropClass(): BackdropClass {
    return "intrinsic";
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { route: FdRoute | null },
    private router: Router,
    private fdRouterService: FdRouterService,
    private applicationApiService: ApplicationApiService,
    private entityApiService: EntityApiService,
    private mediaApiService: MediaApiService,
    private userListApiService: UserListApiService,
    private userFavoriteApiService: UserFavoriteApiService
  ) {
    super();
    this.setHash(false);
  }

  ngOnInit(): void {
    this.watchCategoryCounts();
  }

  override ngOnDestroy(): void {
    this.searchSubscribe.unsubscribe();
  }

  ngAfterViewInit(): void {
    setTimeout(() => (this.categoryResults = this.defaultCategoryResults()));
  }

  navigate(url: string) {
    this.router.navigateByUrl(url);
    this.close();
  }

  addFavorite(id: number, applicationId: number) {
    this.userFavoriteApiService.post({ id, applicationId } as UserFavoriteInterface).subscribe(() => {
      this.resetRequestResults("Favorites");
      this.resetCategoryCounts("Favorites");
    });
  }

  removeFavorite(id: number, applicationId: number) {
    const favorites = this.categoryResults.find(c => c.category === "Favorites");

    if (favorites) {
      favorites.results = null;
    }

    this.userFavoriteApiService.delete({ id, applicationId } as UserFavoriteInterface).subscribe(() => {
      this.resetRequestResults("Favorites");
      this.resetCategoryCounts("Favorites");
    });
  }

  dropFavorite(event: CdkDragDrop<UserFavoriteInterface[]>) {
    moveItemInArray(
      this.categoryResults.find(c => c.category === "Favorites").results,
      event.previousIndex,
      event.currentIndex
    );
    this.userFavoriteApiService.order(this.categoryResults.find(x => x.category === "Favorites").results).subscribe();
  }

  trackCategoyCount(_: any, item: CategoryCount) {
    return `${item.category}-${item.count}`;
  }

  toggleActiveCategory(category: Category) {
    this.activeCategory = category;
  }

  showAllItems(category: Category) {
    this.activeCategory = category;
    const categoryResult = this.categoryResults.find(r => r.category === category);
    if (categoryResult.totalCount() > categoryResult.results.length) {
      this.categoryTops.set(this.activeCategory, null);
      this.resetRequestResults(this.activeCategory);
    }
  }

  defaultCategoryTops() {
    return new Map<Category, number>([
      ["Apps", SearchDialogComponent.detailsItemCount],
      ["Favorites", null],
      ["Team", SearchDialogComponent.detailsItemCount],
      ["Documents", SearchDialogComponent.detailsItemCount],
      ["CR", SearchDialogComponent.detailsItemCount],
      ["Entity", SearchDialogComponent.detailsItemCount],
    ]);
  }

  toggleBookmark(app: ApplicationInterface, view: ApplicationInterface) {
    if ((view.isFavorite = !view.isFavorite)) {
      this.addFavorite(app === view ? 0 : view.id, app.id);
    } else {
      this.removeFavorite(app === view ? 0 : view.id, app.id);
    }
  }

  private resetRequestResults(category: Category) {
    if (category === "All") {
      return; // All category isn't a request
    }

    const defaultCategoryResults = this.defaultCategoryResults();
    this.categoryResults[this.categoryResults.findIndex(r => r.category === category)] = defaultCategoryResults.find(
      r => r.category === category
    );
  }

  /** Persist the state of this dialog in a hash so that the user can refresh the page to this state. */
  private setHash(replaceState: boolean) {
    const pathParts: [string, string] = [SearchDialogComponent.hashId, this.searchFormControl?.value || ""];
    this.fdRouterService.setFragment(new FdRoute({ pathParts }), replaceState);
  }

  private defaultCategoryResults(): CategoryResult[] {
    const totalCount = (category: Category) => this.categoryCounts?.find(c => c.category === category)?.count;

    const apps: CategoryResult = {
      category: "Apps",
      results$: this.searchFormValueChanges$.pipe(
        tap((): void => (apps.results = null)),
        switchMap(() =>
          this.applicationApiService.search(this.searchFormControl.value, this.categoryTops.get(apps.category)).pipe(
            tap(result => {
              apps.results = result.map(app => {
                app.viewList = app.viewList.filter(v => v.isView);
                return app;
              });
            })
          )
        )
      ),
      template: this.appsTemplate,
      totalCount: () => totalCount(apps.category),
      resultsCount: () => apps.results?.map(s => s.viewList.length).reduce((acc, cur) => acc + Math.max(cur, 1), 0),
    };

    const team: CategoryResult = {
      category: "Team",
      results$: this.searchFormValueChanges$.pipe(
        tap(_ => (team.results = null)),
        switchMap(() =>
          this.userListApiService.search(this.searchFormControl.value, null, this.categoryTops.get(team.category)).pipe(
            tap(result => {
              team.results = result;
            })
          )
        )
      ),
      template: this.teamTemplate,
      totalCount: () => totalCount(team.category),
      resultsCount: () => team.results?.length,
    };

    const documents: CategoryResult = {
      category: "Documents",
      results$: this.searchFormValueChanges$.pipe(
        tap((): void => (documents.results = null)),
        switchMap(() =>
          this.mediaApiService.search(this.searchFormControl.value, this.categoryTops.get(documents.category)).pipe(
            tap(result => {
              documents.results = result;
            })
          )
        )
      ),
      template: this.documentsTemplate,
      totalCount: () => totalCount(documents.category),
      resultsCount: () => documents.results?.length,
    };

    const cr: CategoryResult = {
      category: "CR",
      results$: this.searchFormValueChanges$.pipe(
        tap((): void => (cr.results = null)),
        switchMap(() =>
          this.entityApiService
            .search(
              {
                searchTerm: this.searchFormControl.value,
                top: this.categoryTops.get(cr.category),
              },
              ...SearchDialogComponent.crTypes
            )
            .pipe(
              tap(result => {
                cr.results = result;
              })
            )
        )
      ),
      template: this.crTemplate,
      totalCount: () => totalCount(cr.category),
      resultsCount: () => cr.results?.length,
    };

    const entity: CategoryResult = {
      category: "Entity",
      results$: this.searchFormValueChanges$.pipe(
        tap((): void => (entity.results = null)),
        switchMap(() =>
          this.entityApiService
            .search(
              {
                searchTerm: this.searchFormControl.value,
                top: this.categoryTops.get(entity.category),
              },
              ...SearchDialogComponent.entityTypes
            )
            .pipe(
              tap(result => {
                entity.results = result;
              })
            )
        )
      ),
      template: this.entityTemplate,
      totalCount: () => totalCount(entity.category),
      resultsCount: () => entity.results?.length,
    };

    const favorites: CategoryResult = {
      category: "Favorites",
      results$: this.searchFormValueChanges$.pipe(
        tap((): void => (favorites.results = null)),
        switchMap(() =>
          this.userFavoriteApiService
            .search(this.searchFormControl.value, this.categoryTops.get(favorites.category))
            .pipe(
              tap(result => {
                favorites.results = result;
              })
            )
        )
      ),
      template: this.favoritesTemplate,
      totalCount: () => totalCount(favorites.category),
      resultsCount: () => favorites.results?.length,
    };

    return [favorites, apps, team, documents, cr, entity];
  }

  private resetCategoryCounts(category: Category) {
    const categoryItem = this.categoryCounts?.find(c => c.category === category);
    switch (category) {
      case "Favorites":
        this.userFavoriteApiService
          .count(this.searchFormControl.value)
          .subscribe(count => (categoryItem.count = count));
        break;
      default:
        throw "Unsupported reload.";
    }
  }

  private watchCategoryCounts() {
    this.searchSubscribe = this.searchFormValueChanges$
      .pipe(
        switchMap((value: string) =>
          forkJoin({
            applicationCount: this.applicationApiService.count(value),
            userCount: this.userListApiService.count(value),
            mediaCount: this.mediaApiService.count(value),
            crCount: this.entityApiService.count(value, ...SearchDialogComponent.crTypes),
            entityCount: this.entityApiService.count(value, ...SearchDialogComponent.entityTypes),
            favoriteCount: this.userFavoriteApiService.count(value),
          }).pipe(
            map(result => {
              if (this.initializing) {
                this.setHash(true);
                this.initializing = false;
              }

              this.categoryCounts = [
                {
                  category: "All",
                  count:
                    result.applicationCount +
                    result.userCount +
                    result.mediaCount +
                    result.crCount +
                    result.entityCount,
                },
                { category: "Favorites", count: result.favoriteCount },
                { category: "Apps", count: result.applicationCount },
                { category: "Team", count: result.userCount },
                { category: "Documents", count: result.mediaCount },
                { category: "CR", count: result.crCount },
                { category: "Entity", count: result.entityCount },
              ];

              const currentIndex = this.categoryCounts.findIndex(r => r.category === this.activeCategory);
              if (this.activeCategory !== "All" && this.categoryCounts[currentIndex].count === 0) {
                const list = this.categoryCounts.slice(currentIndex).concat(this.categoryCounts.slice(1, currentIndex));
                const firstIndex = list.findIndex(s => s.count > 0);
                if (firstIndex > -1) {
                  this.activeCategory = list[firstIndex].category;
                }
              }

              return this.categoryCounts;
            })
          )
        )
      )
      .subscribe();
  }
}
