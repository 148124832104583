<mat-chip-listbox [class]="class">
  <mat-chip-option
    [matBadge]="serverEnvironment !== undefined && clientEnvironment !== serverEnvironment ? '!' : ''"
    matBadgeColor="warn"
    [matMenuTriggerFor]="menu"
    [matTooltip]="
      serverEnvironment !== undefined && clientEnvironment !== serverEnvironment
        ? 'Client environment is ' + AppEnvironment[clientEnvironment]
        : ''
    ">
    <span class="d-none d-md-inline">{{ AppEnvironment[appEnvironment] }}</span>
    <span class="d-sm-inline d-md-none" [matTooltip]="AppEnvironment[appEnvironment]">{{
      AppEnvironment[appEnvironment][0]
    }}</span>
  </mat-chip-option>
  <mat-menu #menu="matMenu">
    <a *ngIf="clientEnvironment === AppEnvironment.Development" mat-menu-item href="/Swagger" target="swagger"
      >Swagger</a
    >
    <a *ngIf="clientEnvironment === AppEnvironment.Development" mat-menu-item href="/rin" target="rin"
      >Request Inspector (Rin)</a
    >
  </mat-menu>
</mat-chip-listbox>
