import { Injectable } from "@angular/core";
import { ControllerBase } from "@fd/core";
import { Observable } from "rxjs";
import { UserFavoriteInterface } from "src/app/modules/shared/models/common/user-favorite.model";

@Injectable({ providedIn: "root" })
export class UserFavoriteApiService extends ControllerBase<UserFavoriteInterface> {
  constructor() {
    super("Common/UserFavorite");
  }

  //#region super implementation
  get(): Observable<UserFavoriteInterface[]> {
    return super.$get<UserFavoriteInterface[]>();
  }

  //getById(id: string) {
  //  return super.$getById(id);
  //}

  post(model: UserFavoriteInterface) {
    return super.$post(model);
  }

  //put(model: UserFavoriteInterface) {
  //  return super.$put(model);
  //}
  //
  delete(model: UserFavoriteInterface) {
    return super._delete(model.id, { query: { applicationId: model.applicationId } });
  }

  count(searchTerm: string = ""): Observable<number> {
    return super._count({ query: { searchTerm } });
  }

  //count(searchTerm: string) {
  //  return super._count({ query: { searchTerm } });
  //}
  //#endregion

  order(list: UserFavoriteInterface[]) {
    return super._post<UserFavoriteInterface[]>(list, { url: this.order.name });
  }

  search(searchTerm: string, top?: number): Observable<UserFavoriteInterface[]> {
    return super._getBySearch<UserFavoriteInterface[]>(searchTerm, { query: { top } });
  }
}
