import { Injectable } from "@angular/core";
import { ControllerBase } from "@fd/core";
import { Observable } from "rxjs";
import { ApplicationHelpInterface } from "src/app/modules/shared/models/common/application-help.model";

@Injectable({ providedIn: "root" })
export class ApplicationHelpApiService extends ControllerBase<ApplicationHelpInterface> {
  constructor() {
    super("Common/ApplicationHelp");
  }

  //#region super implementation
  get(areaName: string, controllerName: string): Observable<ApplicationHelpInterface> {
    return super._get<ApplicationHelpInterface>({ url: "single", query: { areaName, controllerName } });
  }

  //getById(id: number) {
  //  return super.$getById(id);
  //}
  //
  //post(model: ApplicationHelpInterface) {
  //  return super.$post(model);
  //}
  //
  //put(model: ApplicationHelpInterface) {
  //  return super.$put(model);
  //}
  //
  //delete(id: number) {
  //  return super.$delete(id);
  //}

  count(areaName: string, controllerName: string, excludeId: number = null): Observable<number> {
    return super._count({ query: { areaName, controllerName, excludeId } });
  }
  //#endregion

  //search(searchTerm: string) {
  //  return super.$getBySearch(searchTerm);
  //}
}
