<div class="toolbar-container">
  <mat-toolbar class="px-2">
    <a routerLink="/">
      <img src="assets/images/logo-color.png" alt="{{ appTitle }}" />
    </a>
  </mat-toolbar>
  <mat-divider />
</div>

<mat-nav-list class="pt-0 overflow-y-scroll h-100">
  <!-- Recursive list of navigation items with potential nesting-->
  <ng-container
    *ngFor="let link of root.children"
    [ngTemplateOutlet]="nestedNavItem"
    [ngTemplateOutletContext]="{ link: link, level: 0 }" />
</mat-nav-list>

<ng-template #nestedNavItem let-link="link" let-level="level">
  <div *ngIf="link.children && link.showInMenu" [hidden]="!hasAccess(link)">
    <ng-container *ngIf="link.label; else flat">
      <mat-expansion-panel dense class="mat-elevation-z0 px-0" [expanded]="anyDescendentsActive(link)">
        <mat-expansion-panel-header [class.active]="anyDescendentsActive(link)">
          <span class="level" style="--level: {{ level }}">{{ link.label }}</span>
        </mat-expansion-panel-header>

        <mat-nav-list dense class="pt-0">
          <ng-container
            *ngFor="let childLink of link.children"
            [ngTemplateOutlet]="nestedNavItem"
            [ngTemplateOutletContext]="{
              link: childLink,
              level: level + 1
            }" />
        </mat-nav-list>
      </mat-expansion-panel>
    </ng-container>
    <ng-template #flat>
      <ng-container
        *ngFor="let childLink of link.children"
        [ngTemplateOutlet]="nestedNavItem"
        [ngTemplateOutletContext]="{
          link: childLink,
          level: level
        }" />
    </ng-template>
  </div>

  <ng-container
    *ngIf="!link.children"
    [ngTemplateOutlet]="navItem"
    [ngTemplateOutletContext]="{ link: link, level: level }" />
</ng-template>

<ng-template #navItem let-link="link" let-level="level">
  <!-- We are not going to allow more than three levels of nested items in the main sidenav -->
  <div *ngIf="level < 3 && link.showInMenu" [hidden]="!hasAccess(link)">
    <a *ngIf="link.externalLink" mat-list-item [href]="link.externalLink" [target]="link.data.target">
      <span style="--level: {{ level }}" class="level mat-list-text d-flex justify-content-between">
        {{ link.label }}
        <mat-icon> open_in_new </mat-icon>
      </span>
    </a>

    <a
      *ngIf="link.routerLink"
      mat-list-item
      [routerLink]="routerLink(link)"
      [queryParams]="queryParams(link)"
      routerLinkActive="active"
      (click)="drawer?.toggle()">
      <span style="--level: {{ level }}" class="level mat-list-text">
        {{ link.label }}
      </span>
    </a>
  </div>
</ng-template>
