<div class="page-container">
  <mat-card>
    <mat-card-header class="mb-4">
      <div class="d-flex align-items-center">
        <mat-icon color="warn" class="me-2">error_outline</mat-icon>
        <h2 mat-card-title class="mb-0">Not Authorized</h2>
      </div>
    </mat-card-header>

    <mat-card-content>
      You are not authorized to access the requested page. Please contact a site administrator to provide you access.
    </mat-card-content>

    <mat-card-actions>
      <a *ngIf="previousUrl" mat-button color="primary" [routerLink]="previousUrl">Previous page</a>
      <a mat-button [color]="previousUrl ? 'accent' : 'primary'" routerLink="/">Back to home</a>
    </mat-card-actions>
  </mat-card>
</div>
