<div mat-dialog-title>
  <h2>Search & Navigate</h2>
</div>

<mat-dialog-content>
  <div class="d-flex flex-column h-100">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Search...</mat-label>
      <input
        #searchInput
        matInput
        [formControl]="searchFormControl"
        autocomplete="off"
        (keydown)="categoryTops = defaultCategoryTops()" />
      <button
        *ngIf="searchFormControl.value"
        matSuffix
        mat-icon-button
        (click)="searchFormControl.patchValue(''); categoryTops = defaultCategoryTops()"
        class="material-icons-outlined">
        <mat-icon>backspace</mat-icon>
      </button>
    </mat-form-field>

    <button
      *ngIf="(isHandset$ | async) && activeCategory !== 'All'"
      class="align-self-start"
      mat-button
      color="accent"
      (click)="toggleActiveCategory('All')">
      <mat-icon>arrow_left</mat-icon>
      View all categories
    </button>

    <mat-divider />

    <mat-drawer-container class="flex-grow-1 bg-white" autosize>
      <mat-drawer mode="side" [opened]="(isHandset$ | async) === false" class="pe-3">
        <mat-action-list>
          <button
            *ngFor="let categoryCount of categoryCounts; trackBy: trackCategoyCount"
            mat-list-item
            (click)="toggleActiveCategory(categoryCount.category)"
            [class.text-primary]="activeCategory === categoryCount.category ? 'primary' : ''">
            {{ categoryCount.category }}
            <ng-container *ngIf="categoryCount.count >= 0">({{ categoryCount.count }})</ng-container>
          </button>

          <i *ngIf="initializing">Loading...</i>
        </mat-action-list>
      </mat-drawer>

      <div class="p-2 results">
        <ng-container *ngFor="let categoryResult of categoryResults">
          <div
            [hidden]="activeCategory !== 'All' && activeCategory !== categoryResult.category"
            [class]="categoryResult.category.toLowerCase()">
            <h3 class="category-header">{{ categoryResult.category }}</h3>

            <ng-container *ngIf="categoryResult.category === 'Favorites'; else notFavorites">
              <div cdkDropList (cdkDropListDropped)="dropFavorite($event)">
                <div *ngFor="let result of categoryResult.results$ | async; let idx = index" cdkDrag>
                  <ng-container
                    *ngIf="categoryResult.results?.length > 0 && (activeCategory !== 'All' || idx < summaryItemCount)">
                    <div class="d-flex align-items-center">
                      <mat-icon
                        *ngIf="
                          !(categoryResult.results?.length > 0 && categoryTops.get(categoryResult.category)) &&
                          activeCategory !== 'All'
                        "
                        cdkDragHandle>
                        drag_indicator
                      </mat-icon>

                      <ng-container
                        [ngTemplateOutlet]="categoryResult.template"
                        [ngTemplateOutletContext]="{
                          result: result,
                          categoryResult: categoryResult
                        }" />
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>

            <ng-template #notFavorites>
              <div *ngFor="let result of categoryResult.results$ | async; let idx = index">
                <ng-container
                  *ngIf="categoryResult.results?.length > 0 && (activeCategory !== 'All' || idx < summaryItemCount)">
                  <ng-container
                    [ngTemplateOutlet]="categoryResult.template"
                    [ngTemplateOutletContext]="{
                      result: result,
                      categoryResult: categoryResult
                    }" />
                </ng-container>
              </div>
            </ng-template>

            <i *ngIf="!categoryResult.results">Loading...</i>
            <i *ngIf="categoryResult.results?.length === 0">No results.</i>

            <button
              *ngIf="
                categoryResult.results?.length > 0 &&
                (categoryTops.get(categoryResult.category) || activeCategory === 'All') &&
                (categoryResult.totalCount() > categoryResult.resultsCount() ||
                  (activeCategory === 'All' && categoryResult.resultsCount() > summaryItemCount))
              "
              class="view-all"
              mat-button
              (click)="showAllItems(categoryResult.category)">
              View all {{ categoryResult.totalCount() }} results
            </button>

            <mat-divider />
          </div>
        </ng-container>

        <ng-template #favoritesTemplate let-favorite="result" let-categoryResult="categoryResult">
          <button mat-icon-button (click)="removeFavorite(favorite.id, favorite.applicationId)">
            <mat-icon>bookmark</mat-icon>
          </button>

          <a
            *ngIf="favorite.absoluteUrl.startsWith('/'); else externalLink"
            mat-button
            (click)="close()"
            [routerLink]="favorite.absoluteUrl.toLowerCase()">
            <ng-container [ngTemplateOutlet]="favoriteName" />
          </a>

          <ng-template #externalLink>
            <a [href]="favorite.absoluteUrl" mat-button target="_blank">
              <ng-container [ngTemplateOutlet]="favoriteName" />
            </a>
          </ng-template>

          <ng-template #favoriteName>
            <i *ngIf="favorite.icon" [class]="favorite.icon"></i>
            <ng-container *ngIf="favorite.id; else favoriteAppName">
              {{ favorite.viewName }}
              <span class="text-muted">({{ favorite.name }})</span>
            </ng-container>
            <ng-template #favoriteAppName>
              {{ favorite.name }}
            </ng-template>
          </ng-template>
        </ng-template>

        <ng-template #appsTemplate let-app="result">
          <div>
            <ng-container [ngTemplateOutlet]="link" [ngTemplateOutletContext]="{ link: app }" />

            <div *ngIf="app.viewList.length" class="ms-4">
              <div *ngFor="let view of app.viewList">
                <ng-container [ngTemplateOutlet]="link" [ngTemplateOutletContext]="{ link: view }" />
              </div>
            </div>
          </div>

          <ng-template #link let-link="link">
            <div class="d-flex align-items-center">
              <button mat-icon-button (click)="toggleBookmark(app, link)">
                <mat-icon>{{ link.isFavorite ? "bookmark" : "bookmark_outlined" }}</mat-icon>
              </button>
              <!-- mat-button seams to glitch here -->
              <a
                *ngIf="link.absoluteUrl?.startsWith('/'); else externalLink"
                class="mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base"
                (click)="close()"
                [routerLink]="link.absoluteUrl.toLowerCase()">
                <i *ngIf="link.icon" [class]="link.icon"></i>
                &nbsp;
                {{ link.name || link.viewName }}
              </a>
            </div>

            <ng-template #externalLink>
              <!-- mat-button seams to glitch here -->
              <a
                [href]="link.absoluteUrl"
                target="_blank"
                class="mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base">
                <i *ngIf="link.icon" [class]="link.icon"></i>
                &nbsp;
                {{ link.name || link.viewName }}
              </a>
            </ng-template>
          </ng-template>
        </ng-template>

        <ng-template #teamTemplate let-teamMember="result">
          <div class="d-flex flex-row flex-wrap">
            <h4 class="col-12 m-0">{{ teamMember.fullName }}</h4>
            <div class="col-6">
              <mat-icon>phone</mat-icon>
              {{ teamMember.phone }}
            </div>
            <div class="col-6 text-muted text-right">
              {{ teamMember.defaultDivisionName }}
            </div>
            <div class="col-6">
              <a mat-button [href]="'mailto:' + teamMember.email" class="p-0">
                <mat-icon>email</mat-icon>
                {{ teamMember.email }}
              </a>
            </div>
            <div class="col-6 text-muted text-right">
              {{ teamMember.defaultCellName }}
            </div>
            <div class="col-6">
              <a mat-button target="_blank" [href]="environment.siteUrls.isUrl + 'Plan/Plan/' + teamMember.id">Plan</a>
              <a
                mat-button
                target="_blank"
                [href]="environment.siteUrls.isUrl + 'Compass/Compass?pivotType=SingleUser&ID=' + teamMember.id">
                Compass
              </a>
              <a
                mat-button
                target="_blank"
                [href]="
                  environment.siteUrls.isUrl +
                  'Compass/Compass?pivotType=SingleUser&ID=' +
                  teamMember.id +
                  '&isFourFive=' +
                  teamMember.isFourFiveTimeTracker
                ">
                45s
              </a>
            </div>
            <div class="col-6 text-muted text-right">Coach: {{ teamMember.coachName }}</div>
          </div>
        </ng-template>

        <ng-template #documentsTemplate let-document="result">
          <mat-list-item>
            <a mat-button [href]="document.link" target="_blank">
              <mat-icon>description</mat-icon>
              {{ document.name }}
            </a>
          </mat-list-item>
        </ng-template>

        <ng-template #crTemplate let-cr="result">
          <mat-list-item>
            <h4 class="col-12 m-0">{{ cr.standardName }}</h4>
            <div class="col-12">
              <a mat-button target="_blank" [href]="environment.siteUrls.isUrl + 'Plan/Plan/' + cr.id">Plan</a>
              <a
                mat-button
                target="_blank"
                [href]="environment.siteUrls.isUrl + 'Compass/Compass?pivotType=AllUserInCR&ID=' + cr.id">
                All CR User
              </a>
              <a
                mat-button
                target="_blank"
                [href]="
                  environment.siteUrls.isUrl + 'Compass/Compass?pivotType=AllUserInCR&ID=' + cr.id + '&isFourFive=True'
                ">
                All CR User 45s
              </a>
            </div>
          </mat-list-item>
        </ng-template>

        <ng-template #entityTemplate let-entity="result">
          <mat-list-item>
            <h4 class="col-12 m-0">{{ entity.standardName }}</h4>
            <div class="col-12">
              <a mat-button target="_blank" [href]="environment.siteUrls.isUrl + 'Plan/Plan/' + entity.id">Plan</a>
              <a
                mat-button
                target="_blank"
                [href]="environment.siteUrls.isUrl + 'Compass/Compass?pivotType=SingleCell&ID=' + entity.id">
                Compass
              </a>
              <a
                mat-button
                target="_blank"
                [href]="environment.siteUrls.isUrl + 'Compass/Compass?pivotType=AllUserInCell&ID=' + entity.id">
                All User
              </a>
              <a
                mat-button
                target="_blank"
                [href]="
                  environment.siteUrls.isUrl +
                  'Compass/Compass?pivotType=AllUserInCell&ID=' +
                  entity.id +
                  '&isFourFive=True'
                ">
                All User 45s
              </a>
            </div>
          </mat-list-item>
        </ng-template>
      </div>
    </mat-drawer-container>

    <mat-divider />
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="close()">Close</button>
</mat-dialog-actions>
