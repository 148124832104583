import { Injectable } from "@angular/core";
import { ControllerBase } from "@fd/core";
import { Observable } from "rxjs";
import { MediaInterface } from "src/app/modules/shared/models/common/media.model";

@Injectable({ providedIn: "root" })
export class MediaApiService extends ControllerBase<MediaInterface> {
  constructor() {
    super("Common/Media");
  }

  //#region super implementation
  //get() {
  //  return super$get();
  //}
  //
  //getById(id: string) {
  //  return super.$getById(id);
  //}
  //
  imageUpload(file: File) : Observable<number> {
    const formData = new FormData();
    formData.append("file", file, file.name);
    return super._post<number>(formData, { url: this.imageUpload.name });
  }
  
  //put(model: MediaInterface) {
  //  return super.$put(model);
  //}
  //
  //delete(id: string) {
  //  return super.$delete(id);
  //}

  count(searchTerm: string = ""): Observable<number> {
    return super._count({ query: { searchTerm } });
  }
  //#endregion

  search(searchTerm: string, top?: number): Observable<MediaInterface[]> {
    return super._getBySearch<MediaInterface[]>(searchTerm, { query: { top } });
  }
}
