<div mat-dialog-title>
  <h2>Application Help</h2>
</div>
<mat-dialog-content>
  <div class="row" *ngIf="!isLoading">
    <app-application-owner [application]="application" removeHostTag />
  </div>
  <div class="row" *ngIf="!isLoading">
    <div class="col-12">
      <mat-tab-group
        [backgroundColor]="'primary'"
        mat-stretch-tabs="false"
        [(selectedIndex)]="selectedIndex"
        mat-align-tabs="start">
        <mat-tab *ngIf="applicationHelp.applicationHelpText" label="Application Help">
          <div class="helpText p-3" [innerHTML]="applicationHelp.applicationHelpText"></div>
        </mat-tab>
        <mat-tab *ngIf="applicationHelp.viewHelpText" label="Page Help">
          <div class="helpText p-3" [innerHTML]="applicationHelp.viewHelpText" style="float: left"></div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <mat-spinner *ngIf="isLoading" class="mx-auto" [diameter]="50" />
</mat-dialog-content>
<mat-dialog-actions *ngIf="applicationHelp">
  <a [href]="editeHelpURL" target="_blank" mat-button>Edit Help</a>
  <button mat-button (click)="closeDialog()">Close</button>
</mat-dialog-actions>
