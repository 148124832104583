<div mat-dialog-title class="d-flex align-items-center justify-content-between">
  <h2 class="pe-3">Change Log</h2>

  <div class="flex-grow-1"></div>

  <div class="d-flex align-items-center">
    <button mat-icon-button color="primary" (click)="changeDate(-7)">
      <mat-icon>arrow_left</mat-icon>
    </button>
    <h3 class="px-1 mb-0">{{ date | date : "shortDate" }}</h3>
    <button mat-icon-button color="primary" (click)="changeDate(7)">
      <mat-icon>arrow_right</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content>
  <mat-list>
    <ng-container *ngFor="let byTitle of contentByTitle | keyvalue">
      <h2>{{ byTitle.key }}</h2>
      <mat-list *ngFor="let bySubtitle of byTitle.value | keyvalue" class="ps-3">
        <mat-list-item *ngFor="let content of bySubtitle.value">
          <strong *ngIf="bySubtitle.key">{{ bySubtitle.key }}:&nbsp;</strong>
          <span class="pb-md-0 pb-2">{{ content.description }}</span>
        </mat-list-item>
      </mat-list>

      <mat-divider class="my-3" />
    </ng-container>
  </mat-list>

  <div *ngIf="contentByTitle.size === 0 && !isLoading">No Records Found</div>

  <mat-spinner *ngIf="isLoading" class="mx-auto" [diameter]="50" />
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="close()" cdkFocusInitial>Close</button>
</mat-dialog-actions>
