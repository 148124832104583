import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  Component,
  Directive,
  Host,
  Input,
  OnDestroy,
  OnInit,
  Self,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { NgControl, UntypedFormControl } from "@angular/forms";
import { MatAutocomplete, MatAutocompleteModule, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { Observable, of, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, finalize, startWith, switchMap, tap } from "rxjs/operators";

@Component({
  selector: "is-autocomplete",
  templateUrl: "./autocomplete.component.html",
  standalone: true,
  imports: [CommonModule, MatAutocompleteModule],
})
export class AutocompleteComponent implements OnInit, OnDestroy {
  @Input() control: UntypedFormControl;
  /** If a string[] is provided, the default filter function will do an case insenstive includes comparison */
  @Input() dataSource: string[] | ((input: string) => Observable<any[]>);
  /** Defaults to 0 if the dataSource is an array and 500ms otherwise */
  @Input() debounce = -1;
  @Input() dataTemplate: TemplateRef<any> = null;
  @Input() minLength: number = 0;

  // MatAutocomplete options
  @Input() panelWidth: string | number = "fit";
  @Input() displayWith: ((value: any) => string) | null;

  @ViewChild(MatAutocomplete, { static: true }) ref: MatAutocomplete;

  private $subscriptions: Subscription;

  filtering = false;
  items: any = [];

  constructor() {
    if (this.debounce < 0) {
      this.debounce = Array.isArray(this.dataSource) ? 0 : 500;
    }
  }

  ngOnInit(): void {
    // we don't need to flicker a loading message if the dataSource is an array.
    const showLoading = !Array.isArray(this.dataSource) || this.debounce > 0;

    this.$subscriptions = this.control.valueChanges
      .pipe(
        startWith(""),
        filter(value => typeof value === "string"),
        tap(() => (this.filtering = showLoading)),
        debounceTime(this.debounce),
        tap(() => (this.filtering = false)), // temporarily turn off loading... in case the user undid their change
        distinctUntilChanged(),
        tap(() => (this.filtering = showLoading)),
        switchMap((value: string) => {
          if (value.length < this.minLength) {
            return of([]);
          }

          if (Array.isArray(this.dataSource)) {
            if (!value) {
              return of(this.dataSource); // return the whole array if no filter is provided
            }

            return of(this.dataSource.filter((data: string) => data.toLowerCase().includes(value.toLowerCase())));
          }

          return (this.dataSource(value) as Observable<string[]>).pipe(finalize(() => (this.filtering = false)));
        })
      )
      .subscribe(items => (this.items = items));
  }

  ngOnDestroy() {
    this.$subscriptions.unsubscribe();
  }
}

// trigger directive
@Directive({
  selector: "[fdAutocompleteTrigger]",
  standalone: true,
})
export class AutocompleteDirective implements AfterViewInit, OnDestroy {
  @Input() matAutocomplete: MatAutocomplete;
  @Input() forceSelection = true;
  @Input() selectedItem: any;

  private $subscriptions: Subscription;

  constructor(
    @Host()
    @Self()
    private readonly autoCompleteTrigger: MatAutocompleteTrigger,
    private readonly control: NgControl
  ) {}

  ngAfterViewInit() {
    if (this.forceSelection) {
      this.$subscriptions = this.autoCompleteTrigger.panelClosingActions.subscribe(e => {
        if (!e || !e.source) {
          if (this.selectedItem == this.control.value) {
            return;
          }

          const selected = this.matAutocomplete.options
            .map(option => option.value)
            .find(option => option === this.control.value);

          if (selected == null) {
            this.control.reset();
          }
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.$subscriptions) {
      this.$subscriptions.unsubscribe();
    }
  }
}
